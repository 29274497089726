import { useState } from "react";
import { useSelector } from "react-redux";
import AddDrone from "../components/Function/AddDrone";
import LeftConsole from "../components/LeftConsole";
import RightConsole from "../components/RightConsole";
import BottomConsole from "../components/BottomConsole";
import ConnectServer from "../components/Function/ConnectServer";

function Index() {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));

    return (
        <>
            <ConnectServer />
            <LeftConsole />
            <RightConsole />
            <BottomConsole />
        </>
    );
}

export default Index;
