import { useDispatch } from "react-redux";
import styled from "styled-components";
import { sendMsg } from "../../../store/reducer/drone";
import { useSelector } from "react-redux";
const Container = styled.div`
    position: absolute;
    right: 20px;
    box-sizing: border-box;
    z-index: 2;
    width: 167px;
`;
const ItemContainer = styled.ul`
    background: #303a39;
    box-shadow: 0px 42.53px 60.07px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(40px);
    border-radius: 10px;
`;
const Item = styled.li`
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-top: 1px solid #4c5554;
    padding: 6px 0 6px 20px;
    cursor: pointer;
    &:nth-child(1) {
        border-top: none;
    }
`;
function SelectMenu() {
    const dispatch = useDispatch();
    const droneMode = [{ value: "STABILIZE" }, { value: "ACRO" }, { value: "ALT_HOLD" }, { value: "AUTO" }, { value: "GUIDED" }, { value: "LOITER" }, { value: "RTL" }, { value: "LAND" }];
    const ugvMode = [{ value: "HOLD" }, { value: "ACRO" }, { value: "LOITER" }, { value: "MANUAL" }, { value: "GUIDED" }, { value: "AUTO" }, { value: "RTL" }]
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));
  
    return (
        <Container>
            <ItemContainer>
                {socket.getIsUGV() === true ? (
                    <>
                    {ugvMode.map((mode) => (
                        <Item
                            key={mode.value}
                            onClick={() => {
                                dispatch(sendMsg("03" + "||" + mode.value + "||" + socket.drone_serial_number));
                            }}
                        >
                            {mode.value}
                        </Item>
                    ))}
                    </>
                ): (
                    <>
                    {droneMode.map((mode) => (
                        <Item
                            key={mode.value}
                            onClick={() => {
                                dispatch(sendMsg("03" + "||" + mode.value + "||" + socket.drone_serial_number));
                            }}
                        >
                            {mode.value}
                        </Item>
                    ))}
                    </>
                )},
            </ItemContainer>
        </Container>
    );
}

export default SelectMenu;
