import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from "react-redux";
import { createVwMarker2D } from "../../Left/Mission/MissionFunctions";
import waypointMarker from "../../../gcs_image/marker/waypoint.svg";
import newWaypointmarker from "../../../gcs_image/marker/waypoint_marker.svg";

import { set_mine_coord } from '../../../store/reducer/map';
import { AIPredictAPI } from '../../../api/mine_apis';
import { calcFromLatLng, createMineLabel } from '../../Function/Cesium';

function HistoryDisplay({ historyData, displayMethod, overlayInit, calcGridCoords, historyGridData, setHistoryGridData, drawPolygon, setMineLabelList }) {
    const dispatch = useDispatch();
    const { vw, map } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
    }));

    useEffect(() => {
        historyGridData.map((grid_data, index) => {
            drawPolygon(grid_data, index, `historyGridData`)
        })
    }, [historyGridData])

    useEffect(() => {
        if (!!historyData) {
            overlayInit();
            if (historyData.data.length > 0) {
                // 격자표시
                if (displayMethod === 'grid') {
                    let grid_arr = [];
                    let a_lat_list = [];
                    historyData.data.map((mine_info, idx) => {
                        const grid_coords = calcGridCoords(mine_info);
                        
                        let index = a_lat_list.findIndex(e => e === grid_coords.a.lat)
                        
                        if (index !== -1) {
                            // 이미 저장된 격자 안에 있는 경우 평균 재계산
                            let temp = grid_arr[index];
                            let newAvg = ((temp.avg * temp.count) + mine_info.prob) / (temp.count + 1);
                            grid_arr[index].avg = newAvg;
                            grid_arr[index].count = temp.count + 1;
                        } else {
                            a_lat_list.push(grid_coords.a.lat)
                            // 저장되지 않았으면 새로 추가
                            grid_arr.push({
                                coords: grid_coords,
                                avg: mine_info.prob,
                                count: 1
                            })
                        }

                        setHistoryGridData(grid_arr);
                    })
                }
                // 좌표표시
                else {
                    historyData.data.map((mine_info, idx) => {
                        const position = {
                            latitude: mine_info.latitude,
                            longitude: mine_info.longitude,
                        }
                        const marker = createVwMarker2D(vw, `historyCoordData${idx}`, newWaypointmarker, position);
                        const mineLabel = createMineLabel(position, String(mine_info.prob));
                        setMineLabelList((prev) => {
                            let newList = [...prev];
                            newList.push(mineLabel);
                            return newList;
                        });
                        
                        const eventHandler = function (windowPosition, ecefPosition, cartographic, featureInfo) {
                            dispatch(set_mine_coord(position));
                            const mine_data = {
                                lat: mine_info.latitude,
                                lng: mine_info.longitude,
                            }
                            displayAIPrediction(mine_info.prob, mine_data)
                        }
                        marker.addEventListener(eventHandler);
                    })
                }
            }
        }
    }, [historyData, displayMethod])

    const displayAIPrediction = useCallback(async (prob, position) => {
        try {
            map.removeObjectById(`AI_polygon`)
            map.removeObjectById(`AI_polyline`)

            const res = await AIPredictAPI(prob);
            const distance_data = {
                front: res.data.data.FrontArea,
                back: res.data.data.BackArea,
                side: res.data.data.SideArea,
            }
            const bounds_points = calcFromLatLng(distance_data, position)

            const NorthPoint = new vw.Coord(bounds_points.north.longitude, bounds_points.north.latitude);
            const EastPoint = new vw.Coord(bounds_points.east.longitude, bounds_points.east.latitude);
            const SouthPoint = new vw.Coord(bounds_points.south.longitude, bounds_points.south.latitude);
            const WestPoint = new vw.Coord(bounds_points.west.longitude, bounds_points.west.latitude);

            const ar = new Array();
            ar.push(NorthPoint);
            ar.push(EastPoint);
            ar.push(SouthPoint);
            ar.push(WestPoint);

            const coordCol = new vw.Collection(ar);
            const polygon = new vw.geom.Polygon(coordCol);
            polygon.setId("AI_polygon");
            // polygon.setFillColor(vw.Color.GOLD);
            polygon.setFillColor(new vw.Color(255, 100, 100, 204));
            polygon.create();
            
            ar.push(NorthPoint);

            const coordCol2 = new vw.Collection(ar);

            // 폴리라인 생성
            const lineString = new vw.geom.LineString(coordCol2);
            lineString.setId("AI_polyline");
            lineString.setFillColor(new vw.Color(255, 255, 255, 204));
            lineString.setWidth(0.5);
            lineString.create();

        } catch (e) {
            console.log(e);
        }
    }, [])

    return (<div></div>)
}

export default HistoryDisplay;