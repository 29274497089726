import styled from "styled-components";
import { BaseCancleBtn } from "../../../GlobalStyled";
import WaypointImage from "../../../gcs_image/marker/waypoint.svg";
import WaypointImageModifying from "../../../gcs_image/marker/waypointRed.svg";
import RoiPoint from "../../../gcs_image/marker/roi.svg";
import { createVwMarker2D, drawLine, get_distance_metres } from "./MissionFunctions";
import { useSelector } from "react-redux";
import { getHeight } from "../../Function/Cesium";
import { useState } from "react";
import { useEffect } from "react";
import { sortPointsClockwise } from "../../Right/Prediction/PredictionFunction";
const Container = styled.div`
    display: flex;
    width: 100%;
    height: 30%;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10%;
    margin-top: 10%;
`;
const Btn = styled(BaseCancleBtn)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    color: ${(props) => (props.isOn ? "red" : "")};
`;

const point1 = [
    { latitude: 35.80261612, longitude: 127.15801239 },
    { latitude: 35.80126953, longitude: 127.15683746 },
    { latitude: 35.79992294, longitude: 127.15779114 },
    { latitude: 35.80103683, longitude: 127.15929413 },
    { latitude: 35.80052567, longitude: 127.16075134 },
    { latitude: 35.79933929, longitude: 127.16146851 },
    { latitude: 35.79842377, longitude: 127.1616745 },
    { latitude: 35.79766846, longitude: 127.16236115 },
    { latitude: 35.7980957, longitude: 127.16242981 },
    { latitude: 35.79770279, longitude: 127.16292572 },
    { latitude: 35.79746246, longitude: 127.16338348 },
    { latitude: 35.79697037, longitude: 127.16377258 },
    { latitude: 35.79624176, longitude: 127.16387939 },
    { latitude: 35.79582977, longitude: 127.16423035 },
];
const point2 = [
    { latitude: 35.80018234, longitude: 127.16532135 },
    { latitude: 35.79961777, longitude: 127.16557312 },
    { latitude: 35.79883194, longitude: 127.16664124 },
    { latitude: 35.79821396, longitude: 127.16777039 },
    { latitude: 35.79735565, longitude: 127.1697464 },
    { latitude: 35.79605484, longitude: 127.16902161 },
    { latitude: 35.79461288, longitude: 127.16845703 },
    { latitude: 35.79429245, longitude: 127.16746521 },
    { latitude: 35.79437637, longitude: 127.16531372 },
    { latitude: 35.79504013, longitude: 127.16480255 },
    { latitude: 35.79541016, longitude: 127.16364288 },
    { latitude: 35.79629517, longitude: 127.16234589 },
    { latitude: 35.79696655, longitude: 127.16133118 },
    { latitude: 35.79755402, longitude: 127.15975952 },
    { latitude: 35.79824829, longitude: 127.15824127 },
    { latitude: 35.79892731, longitude: 127.15692139 },
    { latitude: 35.80001831, longitude: 127.15586853 },
    { latitude: 35.80155182, longitude: 127.15599823 },
    { latitude: 35.80300903, longitude: 127.15718842 },
    { latitude: 35.804142, longitude: 127.15840912 },
    { latitude: 35.80519867, longitude: 127.15964508 },
    { latitude: 35.80596161, longitude: 127.1608429 },
    { latitude: 35.80573273, longitude: 127.16162872 },
    { latitude: 35.80512619, longitude: 127.16231537 },
];

function SetWaypoints({ missionData, setMissionDate, getDroneHomePosition }) {
    const { vw, map, socket } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
    }));
    const [modifyData, setModifyData] = useState({
        isModify: false,
        modifyIndex: 0,
    });
    const [missionIndex, setMissionIndex] = useState(4);

    const createMission = (point) => {
        //아직 구현 중
        map.removeObjectById(`missionLine2D`);
        for (let i = 0; i < missionData.waypoint.length; i++) {
            map.removeObjectById(`wp${i}`);
        }
        let points = [...point];
        for (let i = 0; i < points.length; i++) {
            //getHeight(cartographic.latitudeDD,cartographic.longitudeDD) + Number(missionData.altitude) - Number(socket.getDroneHomePosition().altitude)
            points[i].altitude =
                Number(missionData.altitude) +
                getHeight(points[i].latitude, points[i].longitude) -
                Number(getDroneHomePosition().altitude);
        }
        setMissionDate({
            ...missionData,
            waypoint: points,
        });
        for (let i = 0; i < points.length; i++) {
            createVwMarker2D(vw, `wp${i}`, WaypointImage, points[i]);
        }
        drawLine(vw, points, "missionLine2D");
    };

    // 구역 미션 시 사용
    // 미션 비행 좌표 만들 때 좌표 구하는 계산식
    const createPoints = (bounds, interval, bool) => {
        const points = [];
        let repeat = bounds.length;
        if (bool) repeat = repeat - 1;
        for (let i = 0; i < repeat; i++) {
            const startPoint = bounds[i];
            const endPoint = bounds[(i + 1) % bounds.length];
            if (bool) points.push(startPoint);

            const dy = endPoint.latitude - startPoint.latitude;
            const dx = endPoint.longitude - startPoint.longitude;
            const distance = Math.sqrt(dx * dx + dy * dy) * 1.113195e5; //위경도 사이 거리 -> m 로 변환

            // 일정 간격으로 떨어진 좌표 생성
            for (let j = interval; j <= distance; j += interval) {
                const x = startPoint.longitude + (j / distance) * dx;
                const y = startPoint.latitude + (j / distance) * dy;
                const point = { latitude: y, longitude: x };
                points.push(point);
            }
            // if(bool&&i==repeat-1) points.push(endPoint)
        }

        return points;
    };

    // 구역 미션 시 사용
    // 일정 포인트에서 가장 가까운 포지션
    // bounds : 좌표 배열, location : 좌표 하나
    // bounds 좌표들 중 location이랑 제일 가까운 지점, 배열 몇번째인지 리턴
    const nearPoint = (bounds, location) => {
        let nearPoint = {
            distance: get_distance_metres(location, bounds[0]),
            point: { latitude: 0, longitude: 0 },
            index: 0,
        };

        for (let i = 1; i < bounds.length; i++) {
            let distance = get_distance_metres(location, bounds[i]);
            if (nearPoint.distance > distance) {
                nearPoint = {
                    distance: distance,
                    point: bounds[i],
                    index: i,
                };
            }
        }
        return nearPoint.index;
    };

    const greeadMission = (bounds, interval) => {
        for (let i = 0; i < missionData.waypoint.length; i++) {
            map.removeObjectById(`wp${i}`);
        }
        // 폴리곤 외각 일정 거리마다 웨이포인트 배열 생성
        const missionCoords = createPoints(bounds, interval, false);

        // 좌표간 거리 인터벌보다 작을경우 오류 수정 - if문
        if (missionCoords.length >= 1) {
            let test = []; //변수 이름 수정 필요
            // test에 앞에서 하나, 뒤에서 하나씩 배열 생성 (순회 기준 배열)
            const reverse = [...missionCoords].reverse();
            for (let i = 0; i < missionCoords.length / 2; i++) {
                test.push(missionCoords[i]);
                test.push(reverse[i]);
            }

            // 최종 구역 순회 웨이포인트 배열
            const missionCoords2 = createPoints(test, interval, true);

            // 좌표간 거리 인터벌보다 작을경우 오류 수정
            if (missionCoords2.length <= 0) {
                return missionCoords;
            }

            // 맨 앞에 꼭짓점 추가(시작점)
            missionCoords2.unshift(bounds[nearPoint(bounds, missionCoords2[0])]);
            missionCoords2.push(bounds[nearPoint(bounds, missionCoords2[missionCoords2.length - 1])]);

            // 고도 생성해서 마커 생성
            for (let i = 0; i < missionCoords2.length; i++) {
                missionCoords2[i].altitude =
                    Number(missionData.altitude) +
                    getHeight(missionCoords2[i].latitude, missionCoords2[i].longitude) -
                    Number(socket.getDroneHomePosition().altitude);
                createVwMarker2D(vw, `wp${i}`, WaypointImage, missionCoords2[i]);
            }

            map.removeObjectById(`missionLine2D`);
            drawLine(vw, test, "missionLine2D");

            return missionCoords2;
        } else {
            return missionCoords;
        }
    };

    const addWaypoint = (position) => {
        let newWaypoint = [...missionData.waypoint];
        newWaypoint.push(position);
        setMissionDate({
            ...missionData,
            waypoint: newWaypoint,
        });
    };
    const markerClickEvent = (windowPosition, ecefPosition, cartographic, featureInfo) => {
        if (featureInfo != null) {
            const id = featureInfo.groupId;
            const pointObj = map.getObjectById(id);
            pointObj.setImage(WaypointImageModifying);
            pointObj.create();
            setModifyData({
                isModify: true,
                modifyIndex: Number(pointObj.id.substring(2)),
            });
        }
    };

    const create2DLine = useEffect(() => {
        if (missionData.waypoint.length > 1) {
            map.removeObjectById(`missionLine2D`);
            drawLine(vw, missionData.waypoint, `missionLine2D`);
        }
    }, [missionData]);
    //let missionIndex = 2;//지우고 수정 필요

    const setBoundsEvent = useEffect(() => {
        let clickEvent;
        if (missionIndex === 3) {
            clickEvent = (windowPosition, ecefPosition, cartographic) => {
                let temp;
                temp = [...missionData.bounds];
                const position = { latitude: cartographic.latitudeDD, longitude: cartographic.longitudeDD };
                temp.push(position);
                temp = sortPointsClockwise(temp);

                let point = createVwMarker2D(vw, `bound${missionData.bounds.length}`, WaypointImageModifying, position);

                map.removeObjectById("boundsLine2D");
                let line = [...temp];
                line.push(temp[0]);
                let boundLine = drawLine(vw, line, "boundsLine2D");
                boundLine.setFillColor(vw.Color.WHITE);
                boundLine.redraw();
                let mission = [];
                if (temp.length > 2) {
                    mission = greeadMission(temp, 100);
                }

                setMissionDate({
                    ...missionData,
                    bounds: temp,
                    waypoint: mission,
                });
            };
            map.onClick.addEventListener(clickEvent);
        }

        return () => {
            map.onClick.removeEventListener(clickEvent);
        };
    }, [vw, map, missionIndex, missionData]);

    const mapClickEvent = useEffect(() => {
        let clickEvent;
        if (missionIndex === 4) {
            for (let i = 0; i < missionData.waypoint.length; i++) {
                let wpMarker = map.getObjectById(`wp${i}`);
                if (wpMarker != undefined) wpMarker.removeEventListener();
                wpMarker.addEventListener(markerClickEvent);
            }
            clickEvent = (windowPosition, ecefPosition, cartographic) => {
                const missionAlt =
                    getHeight(cartographic.latitudeDD, cartographic.longitudeDD) +
                    Number(missionData.altitude) -
                    Number(socket.getDroneHomePosition().altitude);
                const position = {
                    latitude: cartographic.latitudeDD,
                    longitude: cartographic.longitudeDD,
                    altitude: missionAlt,
                };
                let point;
                if (modifyData.isModify) {
                    const markerId = `wp${modifyData.modifyIndex}`;
                    map.removeObjectById(markerId);
                    point = createVwMarker2D(vw, markerId, WaypointImage, position);
                    setModifyData({
                        ...modifyData,
                        isModify: false,
                    });
                    let newWaypoint = [...missionData.waypoint];
                    newWaypoint[modifyData.modifyIndex] = position;
                    setMissionDate({
                        ...missionData,
                        waypoint: newWaypoint,
                    });
                    point.addEventListener(markerClickEvent);
                } else {
                    point = createVwMarker2D(vw, `wp${missionData.waypoint.length}`, WaypointImage, position);
                    addWaypoint(position);
                }
                point.addEventListener(markerClickEvent);
            };
            map.onClick.addEventListener(clickEvent);
        }

        return () => {
            map.onClick.removeEventListener(clickEvent);
            for (let i = 0; i < missionData.waypoint.length; i++) {
                let wpMarkers = map.getObjectById(`wp${i}`);
                if (wpMarkers != undefined) wpMarkers.removeEventListener();
            }
        };
    }, [vw, map, missionData, modifyData, socket, missionIndex]);

    const delteBefore = () => {
        map.removeObjectById(`missionLine2D`);
        map.removeObjectById("boundsLine2D");
        for (let i = 0; i < missionData.waypoint.length; i++) {
            map.removeObjectById(`wp${i}`);
        }
        for (let i = 0; i < missionData.bounds.length; i++) {
            map.removeObjectById(`bound${i}`);
        }
        setMissionDate({
            ...missionData,
            waypoint: [],
            bounds: [],
        });
    };

    useEffect(() => {
        let clickEvent;
        if (missionIndex === 5) {
            clickEvent = (windowPostion, ecefPosition, cartographic) => {
                try {
                    const position = {
                        latitude: cartographic.latitudeDD,
                        longitude: cartographic.longitudeDD,
                    };

                    let point = createVwMarker2D(vw, `test`, WaypointImage, position);

                    // 지구 분할
                    const MAP_GPS_DISTANCE_PER_KM_LAT = 0.008983152877;
                    const MAP_GPS_DISTANCE_PER_KM_LNG = 0.010967299711;

                    const MAP_GPS_DISTANCE_PER_M_LAT = 0.000008983152877;
                    const MAP_GPS_DISTANCE_PER_M_LNG = 0.000010967299711;

                    const dividedLat = position.latitude / MAP_GPS_DISTANCE_PER_M_LAT;
                    const dividedLng = position.longitude / MAP_GPS_DISTANCE_PER_M_LNG;

                    const floorLat = Math.floor(dividedLat);
                    const floorLng = Math.floor(dividedLng);

                    const aLat = floorLat * MAP_GPS_DISTANCE_PER_M_LAT;
                    const aLng = floorLng * MAP_GPS_DISTANCE_PER_M_LNG;

                    const cLat = aLat + MAP_GPS_DISTANCE_PER_M_LAT;
                    const cLng = aLng + MAP_GPS_DISTANCE_PER_M_LNG;

                    // console.log("dividedLat: ", dividedLat)
                    // console.log("dividedLng: ", dividedLng)
                    // console.log("dividedLat 정수부분: ", floorLat)
                    // console.log("dividedLng 정수부분: ", floorLng)

                    const pointA = new vw.Coord(aLng, aLat);
                    const pointB = new vw.Coord(aLng, cLat);
                    const pointC = new vw.Coord(cLng, cLat);
                    const pointD = new vw.Coord(cLng, aLat);

                    const coordArr = [];
                    coordArr.push(pointA);
                    coordArr.push(pointB);
                    coordArr.push(pointC);
                    coordArr.push(pointD);

                    const coordCol = new vw.Collection(coordArr);
                    const polygon = new vw.geom.PolygonZ(coordCol);
                    polygon.setId("polygon1");
                    polygon.setFillColor(new vw.Color(122, 0, 0, 50));
                    // polygon.setFillColor(vw.Color.GOLD);
                    polygon.setDistanceFromTerrain(50);
                    // polygon.setFillColor(new vw.Color(122, 255, 255, 180));
                    polygon.create();

                    // const A = {
                    //   lat: scaledDiffLat,
                    // }
                } catch (e) {
                    console.log(e);
                }
            };

            map.onClick.addEventListener(clickEvent);
        }
    }, [missionIndex]);

    return (
        <Container>
            <Btn
                isOn={missionIndex == 1}
                onClick={() => {
                    delteBefore();
                    createMission(point1);
                    setMissionIndex(1);
                }}
            >
                1
            </Btn>
            <Btn
                isOn={missionIndex == 2}
                onClick={() => {
                    delteBefore();
                    createMission(point2);
                    setMissionIndex(2);
                }}
            >
                2
            </Btn>
            <Btn
                isOn={missionIndex == 3}
                onClick={() => {
                    delteBefore();
                    setMissionIndex(3);
                }}
            >
                3
            </Btn>
            <Btn
                isOn={missionIndex == 4}
                onClick={() => {
                    delteBefore();
                    setMissionIndex(4);
                }}
            >
                4
            </Btn>
            <Btn
                isOn={missionIndex == 5}
                onClick={() => {
                    delteBefore();
                    setMissionIndex(5);
                }}
            >
                5
            </Btn>
        </Container>
    );
}

export default SetWaypoints;
