import React, {useEffect, useCallback, useState} from 'react';
import { useSelector } from "react-redux";
import RealtimeDrone from './RealtimeDrone';

function RealtimeDisplay({ realtimeGridData, setRealtimeGridData, realtimeData, setRealtimeData, viewFrame, timeOption, displayMethod, calcGridCoords, overlayInit, drawPolygon, calcDroneRealtime, setMineLabelList }) {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));

    // 드론 실시간 데이터
    useEffect(() => {
        if (!!socket && socket !== "") {
            if (!!socket.drone_mine_info.lat && viewFrame === "drone" && timeOption === "realtime") {
                setRealtimeData(prev => {
                    let newList = [...prev];
                    newList.push(socket.drone_mine_info);
                    return newList;
                })
            }
        }
    }, [socket.drone_mine_info])

    // UGV 실시간 데이터
    useEffect(() => {
        if (!!socket && socket !== "") {
            if (!!socket.ugv_mine_info.lat && viewFrame === "ugv" && timeOption === "realtime") {
                setRealtimeData(prev => {
                    let newList = [...prev];
                    newList.push(socket.ugv_mine_info);
                    return newList;
                })
            }
        }
    }, [socket.ugv_mine_info])

    return (
        <div>
            <RealtimeDrone
                setMineLabelList={setMineLabelList}
                displayMethod={displayMethod}
                calcGridCoords={calcGridCoords}
                realtimeData={realtimeData}
                realtimeGridData={realtimeGridData}
                setRealtimeGridData={setRealtimeGridData}
                overlayInit={overlayInit}
                drawPolygon={drawPolygon}
                calcDroneRealtime={calcDroneRealtime} />
        </div>
    );
}

export default RealtimeDisplay;