import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_vworld } from "../../store/reducer/map";

function Map() {
    const dispatch = useDispatch();
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));

    const setMap = (lng = 127.080408, lat = 35.861601) => {
        const { vw } = window;
        const mapOptions = new vw.MapOptions(
            vw.BasemapType.GRAPHIC,
            "",
            vw.DensityType.FULL,
            vw.DensityType.BASIC,
            false,
            new vw.CameraPosition(new vw.CoordZ(126.425, 38.196, 83487000), new vw.Direction(-90, 0, 0)),
            new vw.CameraPosition(new vw.CoordZ(lng, lat, 800), new vw.Direction(0, -90, 0))
        );
        const map = new vw.Map("vmap", mapOptions);
        dispatch(set_vworld(vw, map));
        return { vw, map };
    };

    useEffect(() => {
        setMap();
    }, []);

    useEffect(() => {
        let { ws3d } = window;
        let CesiumScene = ws3d.viewer.scene;
        CesiumScene.context.canvas.addEventListener("webglcontextlost", function (event) {
            event.preventDefault();
        });
        CesiumScene.context.canvas.addEventListener("webglcontextrestored", function () {
            const droneStatus = socket.getDronePosition();
            setMap(droneStatus.longitude, droneStatus.latitude);
        });
    }, [dispatch, socket]);

    return <div id="vmap" style={{ width: "100vw", height: "100vh", position: "fixed" }}></div>;
}
export default Map;
