
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ItemContainer = styled.ul`
  display: flex;
  margin-bottom: 13px;
`
const Item = styled.li`
  font-weight: 500;
  font-size: 16px;
  /* #525D59 */
  color: ${props=>props.isfocus?'#FFFFFF':'#BBC1BE'};
  border-left: 0.5px solid gray;
  padding: 0 13px;
  cursor: pointer;
  &:nth-child(1){
    border-left: none;
  }
`
const BodyTextContaiiner = styled.ul`
  width: 100%;
  background: linear-gradient(180deg, rgba(16, 18, 23, 0.15) 0%, rgba(3, 6, 15, 0.25) 100%);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.29947);
  backdrop-filter: blur(30px);
  border-radius: 30px 30px 0px 0px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const BodyText = styled.li`
  width: 60%;
  display: flex;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #FFFFFF;
  /* box-sizing: border-box; */
  padding-left: 10%;
`
const BodtTextScore = styled.span`
  display: block;
  width: 20%;
  text-align: right;
  margin-right: 10px;
`
const BodtTextEx = styled.span`
  font-weight: 300;
`
function GuideText(){
  const [focusNum,setFocusNum] = useState(0);
  const titles = ['습도','풍속','경사도'];
  const bodyTexts=[
    [
      {score : '40점', ex: '0%이상 ~ 20%미만'},
      {score : '20점', ex: '20%이상 ~ 40%미만'},
      {score : '0점', ex: '40%이상 ~ 60%미만'},
      {score : '-30점', ex: '60%이상 ~ 80%미만'},
      {score : '-50점', ex: '80%이상 ~ 100%미만'},
    ],
    [
      {score : '50점', ex: '7.5m/s이상'},
      {score : '40점', ex: '5m/s이상 ~ 7.5m/s미만'},
      {score : '30점', ex: '2.5m/s이상 ~ 5m/s미만'},
      {score : '20점', ex: '0m/s이상 ~ 2.5m/s미만'},
    ],
    [
      {score : '20점', ex: '+20도 이상'},
      {score : '10점', ex: '+10도이상 ~ +20도미만'},
      {score : '0점', ex: '0도이상 ~ +10도미만'},
      {score : '-10점', ex: '-10도이상 ~ 0도미만'},
      {score : '-20점', ex: '-20도이상 ~ -10도미만'},
    ],
  ];
  return(
    <Container>
      <ItemContainer>
        {titles.map((title,idx)=>(
          <Item key={title} onClick={()=>{setFocusNum(idx)}} isfocus={focusNum==idx}>{title}</Item>
        ))}
      </ItemContainer>
      <BodyTextContaiiner>
        {bodyTexts[focusNum].map((bodyText)=>(
          <BodyText key={bodyText.score}>
            <BodtTextScore>{bodyText.score}</BodtTextScore>
            <BodtTextEx>{bodyText.ex}</BodtTextEx>
          </BodyText>
        ))}
      </BodyTextContaiiner>
    </Container>
  )
}

export default GuideText;