import styled from "styled-components";
import Prediction from "./Right/Prediction/Prediction";
import Weather from "./Right/Weather/Wether";
import { useState } from "react";
import State from "./Right/State/State";
import PowerpackData from "./Right/PowerpackData/PowerpackData";
import { useSelector } from "react-redux";
const Container = styled.div`
    position: absolute;
    overflow: scroll;
    top: 152px;
    right: 62px;
    z-index: 2;
`;

function RightConsole() {
    const [data, setData] = useState([]);
    const { socket, updateSwitch } = useSelector((state) => ({
        socket: state.drone.socket,
        updateSwitch: state.drone.updateSwitch,
    }));
    return (
        <>
            <State />
        </>
    );
}

export default RightConsole;
