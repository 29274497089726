import { useSelector } from "react-redux";
import styled from "styled-components";
import dropdownImage from "../../../gcs_image/left_menu/mode_change_dropdown.svg";
import SelectMenu from "./SelectMenu";
import { useState } from "react";
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5vh;
`;
const Title = styled.span`
    font-weight: 500;
    font-size: 21px;
    color: #eaeaea;
`;
const ModeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Value = styled.span`
    font-weight: 500;
    font-size: 21px;
    color: #0d53e7;
`;
const DropDown = styled.img`
    cursor: pointer;
    transform: ${(props) => (props.ismenuOpen ? "rotate(180deg)" : "none")};
`;
function FlightMode() {
    const { socket, updateSwitch } = useSelector((state) => ({
        socket: state.drone.socket,
        updateSwitch: state.drone.updateSwitch,
    }));
    const [isOpenSelectMenu, setIsOpenSelectMenu] = useState(false);
    return (
        <>
            <Container>
                <Title>Flight Mode</Title>
                {!!socket && socket !== "" && (
                    <ModeContainer>
                        <Value>{socket.getDroneStatus().mode}</Value>
                        <DropDown
                            ismenuOpen={isOpenSelectMenu}
                            src={dropdownImage}
                            onClick={() => {
                                setIsOpenSelectMenu(!isOpenSelectMenu);
                            }}
                        />
                    </ModeContainer>
                )}
            </Container>
            {isOpenSelectMenu ? <SelectMenu /> : null}
        </>
    );
}

export default FlightMode;
