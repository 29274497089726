import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import MineCalendar from "./MineCalendar";
import MineData from "./MineData";
import { useSelector } from "react-redux";
import WarningMessage from "../WarningMessage/WarningMessage";

const S = {
    Wrap: styled.div`
        width: 100%;
        height: fit-content;
        /* height: 300px; */
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 18px;
            color: #fff;
            margin-bottom: 10px;
        }
    `,
    CoordWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 10px 0;
        margin-bottom: 10px;
        
        .coord-wrap {
            display: flex;
            gap: 10px;

            .coord-title {
                text-align: center;
            }

            .coord-div {
                background-color: #00000077;
                width: 50%;
                height: 15px;
                padding: 6px 10px;
                display: flex;
                align-items: center;
            }

            p {
                font-size: 12px;
                line-height: 14px;
                color: #fff;
            }
        }

        .divide-line {
            width: 100%;
            height: 1px;
            background-color: #d3d3d3;
        }
    `,
    MineOptionBtnWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 15px;
    `,
    MineOptionWrap: styled.div`
        width: 100%;
        display: flex;
        gap: 10px;
    `,
    MineOptionBtn: styled.div`
        width: 50%;
        padding: 15px;
        border: 1px solid gray;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.color};
        cursor: pointer;

        p {
            font-size: 14px;
            line-height: 12px;
            color: #fff;
        }
    `,
};

function MineOption(props) {
    const { coord } = useSelector((state) => ({
        coord: state.map.coord,
    }))
    const [displayMethod, setDisplayMethod] = useState("grid"); // grid / coord
    const [viewFrame, setViewFrame] = useState("drone"); // drone / ugv
    const [timeOption, setTimeOption] = useState("realtime"); // realtime, history
    const [selectedDate, setSelectedDate] = useState("");

    return (
        <S.Wrap>
            <MineData displayMethod={displayMethod} viewFrame={viewFrame} timeOption={timeOption} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

            <p className="title">Mine Option</p>

            {displayMethod === "coord" && !!coord && (
                <S.CoordWrap>
                    <div className="divide-line" />
                    <div className="coord-wrap">
                        <p className="coord-title">Marker Position</p>
                        <div className="coord-div">
                            <p>{coord.latitude}</p>
                        </div>
                        <div className="coord-div">
                            <p>{coord.longitude}</p>
                        </div>
                    </div>
                    <div className="divide-line" />
                </S.CoordWrap>
            )}

            <S.MineOptionBtnWrap>
                <S.MineOptionWrap>
                    <S.MineOptionBtn
                        color={displayMethod === "grid" ? "#242424" : "transperant"}
                        onClick={() => setDisplayMethod("grid")}
                    >
                        <p>Grid</p>
                    </S.MineOptionBtn>
                    <S.MineOptionBtn
                        color={displayMethod === "coord" ? "#242424" : "transperant"}
                        onClick={() => setDisplayMethod("coord")}
                    >
                        <p>Coord</p>
                    </S.MineOptionBtn>
                </S.MineOptionWrap>
                
                <S.MineOptionWrap>
                    <S.MineOptionBtn
                        color={viewFrame === "drone" ? "#242424" : "transperant"}
                        onClick={() => setViewFrame("drone")}
                    >
                        <p>Drone</p>
                    </S.MineOptionBtn>
                    <S.MineOptionBtn
                        color={viewFrame === "ugv" ? "#242424" : "transperant"}
                        onClick={() => setViewFrame("ugv")}
                    >
                        <p>UGV</p>
                    </S.MineOptionBtn>
                </S.MineOptionWrap>
                <S.MineOptionWrap>
                    <S.MineOptionBtn
                        color={timeOption === "realtime" ? "#242424" : "transperant"}
                        onClick={() => setTimeOption("realtime")}
                    >
                        <p>Realtime</p>
                    </S.MineOptionBtn>
                    <S.MineOptionBtn
                        color={timeOption === "history" ? "#242424" : "transperant"}
                        onClick={() => setTimeOption("history")}
                    >
                        <p>History</p>
                    </S.MineOptionBtn>
                </S.MineOptionWrap>
            </S.MineOptionBtnWrap>
            {timeOption === "history" ?
                <MineCalendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    viewFrame={viewFrame} />
                :
                <WarningMessage />}
        </S.Wrap>
    );
}

export default MineOption;
