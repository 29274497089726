import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getHeight } from "../../Function/Cesium";
import { setFlightMode,setMessage } from "../../../store/reducer/drone";

const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  top: 60px;
  right: 62px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 68.06%);
  border-top: 1px solid rgba(255, 255, 255, 0.36);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
  backdrop-filter: blur(30px);
  border-radius: 80px;
`

const ItemContainer = styled.ul`
  display: flex;
  padding: 22px 42px;
`
const Item = styled.li`
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  box-sizing: border-box;
  border-right: 0.5px solid #758384;
  color: #FFFFFF;
  &:nth-child(1){
    padding-left: 0px;
  }
  &:last-child{
    border-right: none;
    padding-right:0px
  }
`
const ItemText1 = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
`
const ItemText2 = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  width: 40px;
  text-align: center;
`

function State(){
  const {socket,updateSwitch} = useSelector(state=>({
    socket: state.drone.socket,
    updateSwitch : state.drone.updateSwitch
  }))
  
  const round = (num, digit) => {
    if((Number(digit)%10)===0){
      return Math.round(Number(num)*Number(digit))/Number(digit);
    }else{
      return num;
    }
  }

  return(
    <Container>
      <ItemContainer>
          {/* <Item style={socket.getDroneStatus().efk>0.5?socket.getDroneStatus().efk>0.8?{color:'red'}:{color:'yellow'}:{}}> */}
        {/* <Item style={(!!socket && socket !== "") && (socket.getEkf().efk_ok?socket.getEkf().efk>0.5?socket.getEkf().efk>0.8?{color:'red'}:{color:'yellow'}:{}:{color:'red'})}> */}
        <Item style={
          (!!socket && socket !== "") ? (
            socket.getEkf().efk_ok ?
              socket.getEkf().efk > 0.5 ?
                socket.getEkf().efk > 0.8 ?
                  { color: 'red' }
                  :
                  { color: 'yellow' }
                : {}
              : {color: 'red'}
          )
            : {}
        }>
            <ItemText1>Ekf</ItemText1>
            <ItemText2>{(!!socket && socket !== "") && (socket.getEkf().efk_ok?socket.getEkf().efk:'1.00')}</ItemText2>
          </Item>
          <Item>
            <ItemText1>Gps hdop</ItemText1>
            <ItemText2>{!!socket && socket !== "" && socket.getGps().hdop}</ItemText2>
          </Item>
          <Item>
            <ItemText1>Satellite</ItemText1>
            <ItemText2>{!!socket && socket !== "" && socket.getGps().satellite}</ItemText2>
          </Item>
          <Item>
            <ItemText1>Voltage(v)</ItemText1>
            <ItemText2>{!!socket && socket !== "" && round(socket.getBattery().voltage,10)}</ItemText2>
          </Item>
          <Item>
            <ItemText1>YAW(deg)</ItemText1>
            <ItemText2>{!!socket && socket !== "" && socket.getDroneStatus().yaw}</ItemText2>
          </Item>
          {!!socket && socket !== "" && socket.getIsUGV() ? 
            <Item>
              <ItemText1>Ground_Speed(m/s)</ItemText1>
              <ItemText2>{!!socket && socket !== "" && round(socket.getDroneStatus().groundSpeed, 10)}</ItemText2>
            </Item>
            :
            <Item>
              <ItemText1>Air_Speed(m/s)</ItemText1>
              <ItemText2>{!!socket && socket !== "" && round(socket.getDroneStatus().airSpeed,10)}</ItemText2>
            </Item>
          }
          <Item>
            <ItemText1>Home_distance(m)</ItemText1>
            <ItemText2>{!!socket && socket !== "" && round(socket.getDroneStatus().distanceHome,10)}</ItemText2>
          </Item>
          <Item>
            <ItemText1>Relative(m)</ItemText1>
            <ItemText2>{!!socket && socket !== "" && round(socket.getDronePosition().altitude,10)}</ItemText2>
          </Item>
          <Item>
            <ItemText1>Absolute(m)</ItemText1>
            <ItemText2>{!!socket && socket !== "" && round(socket.getTerrainAltitude(),10)}</ItemText2>
          </Item>
      </ItemContainer>
    </Container>
  );
}

export default State;