const SET_VWORLD = 'map/SET_VWORLD';
const SET_FULLSCREEN = 'map/SET_FULLSCREEN';
const SET_MINE_COORD = 'map/SET_MINE_COORD';
const SET_GRID_POLYGON = 'map/SET_GRID_POLYGON';

export const set_vworld = (vworld,map) => ({type:SET_VWORLD, vworld, map});
export const set_fullscreen = bool => ({ type: SET_FULLSCREEN, bool })
export const set_mine_coord = coord => ({ type: SET_MINE_COORD, coord });
export const set_grid_polygon = polygon => ({ type: SET_GRID_POLYGON, polygon });

const initialState = {
  vw : null,
  map : null,
  isFullScreen: false,
  coord: null,
  polygon: [],
}

export default function map(state = initialState, action){
  switch (action.type){
    case SET_VWORLD:
      return{
        ...state,
        vw : action.vworld,
        map : action.map
      }
    case SET_FULLSCREEN:
      return{
        ...state,
        isFullScreen : action.bool
      }
    case SET_MINE_COORD:
      return {
        ...state,
        coord: action.coord
      }
    case SET_GRID_POLYGON:
      return {
        ...state,
        polygon: action.polygon,
      }
    default:
      return state;
  }
}