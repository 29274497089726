import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GetWeatherAPI } from "../../../api/weather_apis";
import BaseInfo from "./BaseInfo";
import WindInfo from "./WindInfo";
import PrecipitationInfo from "./PrecipitationInfo";
// import fire from "../../image/fire.svg";
// import PrecipitationInfo from "./PrecipitationInfo";
// import WindInfo from "./WindInfo";

const Container = styled.div`
  margin-top: 20px;
  background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
  backdrop-filter: blur(30px);
  border-radius: 10px;
  padding: 42px;
  box-sizing: border-box;
`
// const Head = styled.div`
//   width: 100%;
//   height: 54px;
//   border-bottom: 1px solid rgba(255, 255, 255, 0.5);
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   box-sizing: border-box;
//   padding-left: 19px;
//   padding-right: 27px;
// `
// const FireImage = styled.img`
//   margin-right: 8px;
// `
// const HeadText = styled.p`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   color: #FFFFFF;
//   line-height: 54px;
// `
// const WeatherRadio = styled.ul`
//   height: 100%;
//   display: flex;
// `
// const WeatherItem = styled.li`
//   height: 54.5px;
//   width: 35px;
//   margin-left: 30px;
//   cursor: pointer;
//   border-bottom: ${props=>props.isSelect?'2px solid rgba(195,255,226,1)':null};
//   box-sizing: border-box;
//   text-align: center;
// `
function Weather({data,setData}){
  // const [infoType] = useState(['바람','강수']);
  // const [selectNum,setSelectNum] = useState(0);
  
  const [getSwitch,setGetSwitch] = useState(true);
  useEffect(()=>{
    const getweather = async()=>{
      let weather = await GetWeatherAPI();
      setData(weather);
    }
    getweather()
  },[getSwitch]);
  return(
    <Container>
      <BaseInfo data={data[0]} getSwitch={getSwitch} setGetSwitch={setGetSwitch}/>
      <WindInfo data={data}/>
      <PrecipitationInfo data={data}/>
    </Container>
  );
}

export default Weather;