import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { BaseCancleBtn } from "../../../GlobalStyled";
import redWaypointImage from "../../../gcs_image/marker/waypointRed.svg";
import { getHeight } from "../../Function/Cesium";
import WaypointImage from "../../../gcs_image/marker/waypoint.svg";
import NewWaypointImage from "../../../gcs_image/marker/waypoint_marker.svg";
import { sortPointsClockwise } from "../../Right/Prediction/PredictionFunction";
import WaypointImageModifying from "../../../gcs_image/marker/waypointRed.svg";
import { createVwMarker2D, createVwMarker3D, drawLine, get_distance_metres } from "./MissionFunctions";
import { setSpeed, setWaypoint } from "./MissionObject";

import Waypoint1 from "../../../gcs_image/waypointMarker/waypoint_1.svg";
import Waypoint2 from "../../../gcs_image/waypointMarker/waypoint_2.svg";
import Waypoint3 from "../../../gcs_image/waypointMarker/waypoint_3.svg";
import Waypoint4 from "../../../gcs_image/waypointMarker/waypoint_4.svg";
import Waypoint5 from "../../../gcs_image/waypointMarker/waypoint_5.svg";
import Waypoint6 from "../../../gcs_image/waypointMarker/waypoint_6.svg";
import Waypoint7 from "../../../gcs_image/waypointMarker/waypoint_7.svg";
import Waypoint8 from "../../../gcs_image/waypointMarker/waypoint_8.svg";
import Waypoint9 from "../../../gcs_image/waypointMarker/waypoint_9.svg";
import Waypoint10 from "../../../gcs_image/waypointMarker/waypoint_10.svg";
import Waypoint11 from "../../../gcs_image/waypointMarker/waypoint_11.svg";
import Waypoint12 from "../../../gcs_image/waypointMarker/waypoint_12.svg";
import Waypoint13 from "../../../gcs_image/waypointMarker/waypoint_13.svg";
import Waypoint14 from "../../../gcs_image/waypointMarker/waypoint_14.svg";
import Waypoint15 from "../../../gcs_image/waypointMarker/waypoint_15.svg";
import Waypoint16 from "../../../gcs_image/waypointMarker/waypoint_16.svg";
import Waypoint17 from "../../../gcs_image/waypointMarker/waypoint_17.svg";
import Waypoint18 from "../../../gcs_image/waypointMarker/waypoint_18.svg";
import Waypoint19 from "../../../gcs_image/waypointMarker/waypoint_19.svg";
import Waypoint20 from "../../../gcs_image/waypointMarker/waypoint_20.svg";
import RedWaypoint1 from "../../../gcs_image/waypointMarker/red_waypoint_1.svg";
import RedWaypoint2 from "../../../gcs_image/waypointMarker/red_waypoint_2.svg";
import RedWaypoint3 from "../../../gcs_image/waypointMarker/red_waypoint_3.svg";
import RedWaypoint4 from "../../../gcs_image/waypointMarker/red_waypoint_4.svg";
import RedWaypoint5 from "../../../gcs_image/waypointMarker/red_waypoint_5.svg";
import RedWaypoint6 from "../../../gcs_image/waypointMarker/red_waypoint_6.svg";
import RedWaypoint7 from "../../../gcs_image/waypointMarker/red_waypoint_7.svg";
import RedWaypoint8 from "../../../gcs_image/waypointMarker/red_waypoint_8.svg";
import RedWaypoint9 from "../../../gcs_image/waypointMarker/red_waypoint_9.svg";
import RedWaypoint10 from "../../../gcs_image/waypointMarker/red_waypoint_10.svg";
import RedWaypoint11 from "../../../gcs_image/waypointMarker/red_waypoint_11.svg";
import RedWaypoint12 from "../../../gcs_image/waypointMarker/red_waypoint_12.svg";
import RedWaypoint13 from "../../../gcs_image/waypointMarker/red_waypoint_13.svg";
import RedWaypoint14 from "../../../gcs_image/waypointMarker/red_waypoint_14.svg";
import RedWaypoint15 from "../../../gcs_image/waypointMarker/red_waypoint_15.svg";
import RedWaypoint16 from "../../../gcs_image/waypointMarker/red_waypoint_16.svg";
import RedWaypoint17 from "../../../gcs_image/waypointMarker/red_waypoint_17.svg";
import RedWaypoint18 from "../../../gcs_image/waypointMarker/red_waypoint_18.svg";
import RedWaypoint19 from "../../../gcs_image/waypointMarker/red_waypoint_19.svg";
import RedWaypoint20 from "../../../gcs_image/waypointMarker/red_waypoint_20.svg";

const S = {
    Wrap: styled.div`
        display: flex;
        width: 100%;
        height: 30%;
        margin-top: 10%;
        justify-content: center;
        gap: 20px;
    `,
    MissionBtn: styled(BaseCancleBtn)`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        padding: 15px 0;
        color: ${(props) => (props.isOn ? "red" : "")};
    `,
};

function SetMissionWaypoint({ missionData, setMissionData, getDroneHomePosition, missionIndex }) {
    const { vw, map, socket } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
    }));
    const [pathIndex, setPathIndex] = useState(0);
    const [modifyData, setModifyData] = useState({
        isModify: false,
        modifyIndex: 0,
    });
    const [testData, setTestData] = useState([]);

    const mission = [
        // { latitude: 35.800101, longitude: 127.167857, altitude: 30 },
        // { latitude: 35.799508, longitude: 127.166024, altitude: 127 },
        // { latitude: 35.799812, longitude: 127.163409, altitude: 230 },
        // { latitude: 35.800672, longitude: 127.160845, altitude: 260 },
        { latitude: 35.861144, longitude: 127.080471 },
        { latitude: 35.860837, longitude: 127.080359 },
        { latitude: 35.860557, longitude: 127.080735 },
        { latitude: 35.860746, longitude: 127.081108 },
        { latitude: 35.861051, longitude: 127.081091 },
    ]

    useEffect(() => {
        setPathIndex(99);
        // setPathIndex(1);
    }, []);

    useEffect(() => {
        if (pathIndex === 1) {
            if (missionData.waypoint.length > 1) {
                map.removeObjectById(`missionLine2D`);
                drawLine(vw, missionData.waypoint, `missionLine2D`);
            }
        }
    }, [missionData]);

    useEffect(() => {
        let clickEvent;

        if (pathIndex === 1) {
            for (let i = 0; i < missionData.waypoint.length; i++) {
                try {
                    let wpMarker = map.getObjectById(`wp${i}`);
                    if (wpMarker != undefined) wpMarker.removeEventListener();
                    wpMarker.addEventListener(markerClickEvent);
                } catch {
                    console.log("Error");
                }
            }
            clickEvent = (windowPosition, ecefPosition, cartographic) => {
                
                    const missionAlt =
                        getHeight(cartographic.latitudeDD, cartographic.longitudeDD) +
                        Number(missionData.altitude) -
                        Number(socket.getDroneHomePosition().altitude);
                    const position = {
                        latitude: cartographic.latitudeDD,
                        longitude: cartographic.longitudeDD,
                        altitude: missionAlt,
                    };
                    let point;

                    let waypointMarker;
                    if (modifyData.isModify) {
                        waypointMarker = calcWaypointMarker(modifyData.modifyIndex);
                        const markerId = `wp${modifyData.modifyIndex}`;
                        map.removeObjectById(markerId);
                        point = createVwMarker2D(vw, markerId, waypointMarker, position);
                        setModifyData({
                            ...modifyData,
                            isModify: false,
                        });
                        let newWaypoint = [...missionData.waypoint];
                        newWaypoint[modifyData.modifyIndex] = position;
                        setMissionData({
                            ...missionData,
                            waypoint: newWaypoint,
                        });
                        // point.addEventListener(markerClickEvent);
                    } else {
                        if (missionData.waypoint.length < 20) {
                            waypointMarker = calcWaypointMarker();
                            point = createVwMarker2D(vw, `wp${missionData.waypoint.length}`, waypointMarker, position);
                            // addWaypoint(position);
                            let newWaypoint = [...missionData.waypoint];
                            newWaypoint.push(position);
                            setMissionData({
                                ...missionData,
                                waypoint: newWaypoint,
                            });
                        } else {
                            alert("웨이포인트는 최대 20개까지 설정할 수 있습니다.");
                        }
                    }
                    point.addEventListener(markerClickEvent);
            };
            map.onClick.addEventListener(clickEvent);
        } else if (pathIndex === 2) {
            clickEvent = (windowPosition, ecefPosition, cartographic) => {
                let temp;
                temp = [...missionData.bounds];
                const position = { latitude: cartographic.latitudeDD, longitude: cartographic.longitudeDD };
                temp.push(position);
                temp = sortPointsClockwise(temp);

                let point = createVwMarker2D(vw, `bound${missionData.bounds.length}`, WaypointImageModifying, position);

                map.removeObjectById("boundsLine2D");
                let line = [...temp];
                line.push(temp[0]);
                let boundLine = drawLine(vw, line, "boundsLine2D");
                boundLine.setFillColor(vw.Color.WHITE);
                boundLine.redraw();
                let mission = [];
                if (temp.length > 2) {
                    mission = greeadMission(temp, 15);
                }

                setMissionData({
                    ...missionData,
                    bounds: temp,
                    waypoint: mission,
                });
            };
            map.onClick.addEventListener(clickEvent);
        }

        // else if (pathIndex === 3) {
        //     clickEvent = (windowPosition, ecefPosition, cartographic) => {
        //         let temp;
        //         temp = [...missionData.bounds];
        //         const position = { latitude: cartographic.latitudeDD, longitude: cartographic.longitudeDD };
        //         temp.push(position);
        //         temp = sortPointsClockwise(temp);

        //         let point = createVwMarker2D(vw, `bound${missionData.bounds.length}`, WaypointImageModifying, position);

        //         map.removeObjectById("boundsLine2D");
        //         let line = [...temp];
        //         line.push(temp[0]);
        //         let boundLine = drawLine(vw, line, "boundsLine2D");
        //         boundLine.setFillColor(vw.Color.WHITE);
        //         boundLine.redraw();

        //         setMissionData({
        //             ...missionData,
        //             bounds: temp,
        //         });
        //     };

        //     map.onClick.addEventListener(clickEvent);
        // }
        else if (pathIndex === 98) {
            clickEvent = (windowPostion, ecefPosition, cartographic) => {
                try {
                    const position = {
                        latitude: cartographic.latitudeDD,
                        longitude: cartographic.longitudeDD,
                    };

                    let newWaypoint = [...missionData.waypoint];
                    newWaypoint.push(position);
                    setMissionData({
                        ...missionData,
                        waypoint: newWaypoint,
                    });

                    let point = createVwMarker2D(vw, `test${testData.length}`, WaypointImage, position);

                    // 지구 분할
                    const MAP_GPS_DISTANCE_PER_KM_LAT = 0.008983152877;
                    const MAP_GPS_DISTANCE_PER_KM_LNG = 0.010967299711;

                    const MAP_GPS_DISTANCE_PER_M_LAT = 0.000008983152877;
                    const MAP_GPS_DISTANCE_PER_M_LNG = 0.000010967299711;

                    // const dividedLat = position.latitude / MAP_GPS_DISTANCE_PER_KM_LAT;
                    // const dividedLng = position.longitude / MAP_GPS_DISTANCE_PER_KM_LNG;
                    const dividedLat = position.latitude / MAP_GPS_DISTANCE_PER_M_LAT;
                    const dividedLng = position.longitude / MAP_GPS_DISTANCE_PER_M_LNG;

                    const floorLat = Math.floor(dividedLat);
                    const floorLng = Math.floor(dividedLng);

                    const aLat = floorLat * MAP_GPS_DISTANCE_PER_M_LAT;
                    const aLng = floorLng * MAP_GPS_DISTANCE_PER_M_LNG;

                    const cLat = aLat + MAP_GPS_DISTANCE_PER_M_LAT;
                    const cLng = aLng + MAP_GPS_DISTANCE_PER_M_LNG;

                    // const aLat = floorLat * MAP_GPS_DISTANCE_PER_KM_LAT;
                    // const aLng = floorLng * MAP_GPS_DISTANCE_PER_KM_LNG;

                    // const cLat = aLat + MAP_GPS_DISTANCE_PER_KM_LAT;
                    // const cLng = aLng + MAP_GPS_DISTANCE_PER_KM_LNG;

                    // console.log("dividedLat: ", dividedLat)
                    // console.log("dividedLng: ", dividedLng)
                    // console.log("dividedLat 정수부분: ", floorLat)
                    // console.log("dividedLng 정수부분: ", floorLng)

                    const pointA = new vw.Coord(aLng, aLat);
                    const pointB = new vw.Coord(aLng, cLat);
                    const pointC = new vw.Coord(cLng, cLat);
                    const pointD = new vw.Coord(cLng, aLat);

                    const coordArr = [];
                    coordArr.push(pointA);
                    coordArr.push(pointB);
                    coordArr.push(pointC);
                    coordArr.push(pointD);

                    const coordCol = new vw.Collection(coordArr);
                    const polygon = new vw.geom.PolygonZ(coordCol);
                    polygon.setId(`polygon${testData.length}`);
                    // polygon.setFillColor(new vw.Color(122, 0, 0, 50));
                    polygon.setFillColor(vw.Color.RED);
                    // polygon.setDistanceFromTerrain(500);
                    // polygon.setFillColor(new vw.Color(122, 255, 255, 180));
                    polygon.create();
                    // setTestIndex(testIndex + 1);
                    setTestData((prev) => {
                        let newList = [...prev];
                        newList.push(position);
                        return newList;
                    });

                    // const A = {
                    //   lat: scaledDiffLat,
                    // }
                } catch (e) {
                    console.log(e);
                }
            };
            map.onClick.addEventListener(clickEvent);
        }

        return () => {
            map.onClick.removeEventListener(clickEvent);
            for (let i = 0; i < missionData.waypoint.length; i++) {
                let wpMarkers = map.getObjectById(`wp${i}`);
                if (wpMarkers != undefined) wpMarkers.removeEventListener();
            }
        };
    }, [pathIndex, missionData, modifyData, mission]);

    const calcWaypointMarker = useCallback((num = "") => {
        let index;
        if (num === "") {
            index = missionData.waypoint.length;
        } else {
            index = num;
        }
        if (index === 0) {
            return Waypoint1;
        } else if (index === 1) {
            return Waypoint2;
        } else if (index === 2) {
            return Waypoint3;
        } else if (index === 3) {
            return Waypoint4;
        } else if (index === 4) {
            return Waypoint5;
        } else if (index === 5) {
            return Waypoint6;
        } else if (index === 6) {
            return Waypoint7;
        } else if (index === 7) {
            return Waypoint8;
        } else if (index === 8) {
            return Waypoint9;
        } else if (index === 9) {
            return Waypoint10;
        } else if (index === 10) {
            return Waypoint11;
        } else if (index === 11) {
            return Waypoint12;
        } else if (index === 12) {
            return Waypoint13;
        } else if (index === 13) {
            return Waypoint14;
        } else if (index === 14) {
            return Waypoint15;
        } else if (index === 15) {
            return Waypoint16;
        } else if (index === 16) {
            return Waypoint17;
        } else if (index === 17) {
            return Waypoint18;
        } else if (index === 18) {
            return Waypoint19;
        } else if (index === 19) {
            return Waypoint20;
        }
    }, [missionData])

    const calcRedWaypointMarker = useCallback((index) => {
        if (index === 0) {
            return RedWaypoint1;
        } else if (index === 1) {
            return RedWaypoint2;
        } else if (index === 2) {
            return RedWaypoint3;
        } else if (index === 3) {
            return RedWaypoint4;
        } else if (index === 4) {
            return RedWaypoint5;
        } else if (index === 5) {
            return RedWaypoint6;
        } else if (index === 6) {
            return RedWaypoint7;
        } else if (index === 7) {
            return RedWaypoint8;
        } else if (index === 8) {
            return RedWaypoint9;
        } else if (index === 9) {
            return RedWaypoint10;
        } else if (index === 10) {
            return RedWaypoint11;
        } else if (index === 11) {
            return RedWaypoint12;
        } else if (index === 12) {
            return RedWaypoint13;
        } else if (index === 13) {
            return RedWaypoint14;
        } else if (index === 14) {
            return RedWaypoint15;
        } else if (index === 15) {
            return RedWaypoint16;
        } else if (index === 16) {
            return RedWaypoint17;
        } else if (index === 17) {
            return RedWaypoint18;
        } else if (index === 18) {
            return RedWaypoint19;
        } else if (index === 19) {
            return RedWaypoint20;
        }
    }, [])

    // const onClickTest2Btn = useCallback(() => {
    //     let coordArr = [];

    //     console.log(missionData.bounds[0].latitude);
    //     console.log(missionData.bounds[1].latitude);
    //     console.log(missionData.bounds[2].latitude);

    //     let max = 0;
    //     let index = 0;
    //     missionData.bounds.map((coord, idx) => {
    //         if (coord.latitude > max) {
    //             max = coord.latitude;
    //             index = idx;
    //         }
    //     });

    //     coordArr.push(missionData.bounds[index]);
    //     console.log("가장 큰 위도값 : ", max);
    //     console.log("coordArr: ", coordArr);
    // }, [missionData]);

    const markerClickEvent = (windowPosition, ecefPosition, cartographic, featureInfo) => {
        if (featureInfo != null) {
            const id = featureInfo.groupId;
            const pointObj = map.getObjectById(id);
            const modifyImage = calcRedWaypointMarker(parseInt(id.substring(2))); 
            pointObj.setImage(modifyImage);
            pointObj.create();
            setModifyData({
                isModify: true,
                modifyIndex: Number(pointObj.id.substring(2)),
            });
        }
    };

    // 구역 미션 시 사용
    // 미션 비행 좌표 만들 때 좌표 구하는 계산식
    const createPoints = (bounds, interval, bool) => {
        const points = [];
        let repeat = bounds.length;
        if (bool) repeat = repeat - 1;
        for (let i = 0; i < repeat; i++) {
            const startPoint = bounds[i];
            const endPoint = bounds[(i + 1) % bounds.length];
            if (bool) points.push(startPoint);

            const dy = endPoint.latitude - startPoint.latitude;
            const dx = endPoint.longitude - startPoint.longitude;
            const distance = Math.sqrt(dx * dx + dy * dy) * 1.113195e5; //위경도 사이 거리 -> m 로 변환

            // 일정 간격으로 떨어진 좌표 생성
            for (let j = interval; j <= distance; j += interval) {
                const x = startPoint.longitude + (j / distance) * dx;
                const y = startPoint.latitude + (j / distance) * dy;
                const point = { latitude: y, longitude: x };
                points.push(point);
            }
            // if(bool&&i==repeat-1) points.push(endPoint)
        }

        return points;
    };

    // 구역 미션 시 사용
    // 일정 포인트에서 가장 가까운 포지션
    // bounds : 좌표 배열, location : 좌표 하나
    // bounds 좌표들 중 location이랑 제일 가까운 지점, 배열 몇번째인지 리턴
    const nearPoint = (bounds, location) => {
        let nearPoint = {
            distance: get_distance_metres(location, bounds[0]),
            point: { latitude: 0, longitude: 0 },
            index: 0,
        };

        for (let i = 1; i < bounds.length; i++) {
            let distance = get_distance_metres(location, bounds[i]);
            if (nearPoint.distance > distance) {
                nearPoint = {
                    distance: distance,
                    point: bounds[i],
                    index: i,
                };
            }
        }
        return nearPoint.index;
    };

    const greeadMission = (bounds, interval) => {
        for (let i = 0; i < missionData.waypoint.length; i++) {
            map.removeObjectById(`wp${i}`);
        }
        // 폴리곤 외각 일정 거리마다 웨이포인트 배열 생성
        const missionCoords = createPoints(bounds, interval, false);

        // 좌표간 거리 인터벌보다 작을경우 오류 수정 - if문
        if (missionCoords.length >= 1) {
            let test = []; //변수 이름 수정 필요
            // test에 앞에서 하나, 뒤에서 하나씩 배열 생성 (순회 기준 배열)
            const reverse = [...missionCoords].reverse();
            for (let i = 0; i < missionCoords.length / 2; i++) {
                test.push(missionCoords[i]);
                test.push(reverse[i]);
            }

            // 최종 구역 순회 웨이포인트 배열
            const missionCoords2 = createPoints(test, interval, true);

            // 좌표간 거리 인터벌보다 작을경우 오류 수정
            if (missionCoords2.length <= 0) {
                return missionCoords;
            }

            // 맨 앞에 꼭짓점 추가(시작점)
            missionCoords2.unshift(bounds[nearPoint(bounds, missionCoords2[0])]);
            missionCoords2.push(bounds[nearPoint(bounds, missionCoords2[missionCoords2.length - 1])]);

            // 고도 생성해서 마커 생성
            for (let i = 0; i < missionCoords2.length; i++) {
                missionCoords2[i].altitude =
                    Number(missionData.altitude) +
                    getHeight(missionCoords2[i].latitude, missionCoords2[i].longitude) -
                    Number(socket.getDroneHomePosition().altitude);
                createVwMarker2D(vw, `wp${i}`, WaypointImage, missionCoords2[i]);
            }

            map.removeObjectById(`missionLine2D`);
            drawLine(vw, test, "missionLine2D");

            return missionCoords2;
        } else {
            return missionCoords;
        }
    };

    const onClickCancelBtn = useCallback(() => {
        setPathIndex(0);
        map.removeObjectById(`missionLine2D`);
        map.removeObjectById("boundsLine2D");
        for (let i = 0; i < missionData.waypoint.length; i++) {
            map.removeObjectById(`wp${i}`);
        }
        for (let i = 0; i < testData.length; i++) {
            map.removeObjectById(`test${i}`);
            map.removeObjectById(`polygon${i}`);
        }
        for (let i = 0; i < missionData.bounds.length; i++) {
            map.removeObjectById(`bound${i}`);
        }
        setMissionData({
            ...missionData,
            waypoint: [],
            bounds: [],
        });
    }, [map, missionData, testData, missionIndex]);

    const onClickSetRoute = useCallback(() => {
        mission.map((waypoint, idx) => {
            const position = {
                latitude: waypoint.latitude,
                longitude: waypoint.longitude,
            }
            createVwMarker2D(vw, `wp${idx}`, WaypointImage, position);
        })

        // createZigZagPath(0.01, 0.005);

        createZigzagPathAlongPolygon(1);

        // mission.map((waypoint, idx) => {
        //     const position = {
        //         latitude: waypoint.latitude,
        //         longitude: waypoint.longitude,
        //         altitude: waypoint.altitude,
        //     }
        //     createVwMarker3D(vw, `wp${idx}`, WaypointImage, position, socket.getDroneHomePosition().altitude);
        // })

        // setMissionData({
        //     ...missionData,
        //     waypoint: mission,
        // })
        // drawLine(vw, mission, "missionLine2D");


        // map.removeObjectById('missionLine2D');
        // mission.map((waypoint, index) => {
        //     map.removeObjectById(`wp${index}`);
        //     createVwMarker2D(vw, `wp${index}`, WaypointImage, waypoint);
        // })
        // drawLine(vw, mission, "missionLine2D");
        // setMissionData({
        //     ...missionData,
        //     waypoint: mission,
        // })
    }, [mission, missionData])

    const createZigzagPathAlongPolygon = useCallback((distance) => {
        for (let i = 0; i < mission.length; i++) {
            // 현재 좌표와 다음 좌표 구하기
            let currentCoord = mission[i];
            let nextCoord = mission[(i + 1) % mission.length];

            // 기울기 계산
            let slope = (nextCoord.longitude - currentCoord.longitude) / (nextCoord.latitude - currentCoord.latitude);

            // 현재 좌표에서 a + 90도 방향으로 1m 떨어진 지점의 좌표 계산
            let offsetX = distance / Math.sqrt(1 + Math.pow(slope, 2));
            let offsetY = offsetX * slope;

            let newCoord = {
                latitude: currentCoord.latitude + offsetX, 
                longitude: currentCoord.longitude + offsetY
            }

            createVwMarker2D(vw, `zigzag${i}`, NewWaypointImage, newCoord);
        }
    }, [mission])

    const createZigZagPath = useCallback((offsetX, offsetY, center = { latitude: 35.860898, longitude: 127.090750 }) => {
        let numPoints = 20;

        for (var i = 0; i < numPoints; i++) {
            // 폴리곤 외각선에서의 좌표 계산
            let fraction = i / numPoints;
            let index = Math.floor(fraction * 5);
            let startCoord = mission[index];
            let endCoord = mission[(i + 1) % 5];

            // 선분 위에서의 좌표 계산
            let latitude = startCoord.latitude + fraction + (endCoord.latitude - startCoord.latitude);
            let longitude = startCoord.longitude + fraction + (endCoord.longitude - startCoord.longitude);

            latitude += offsetX * Math.pow(-1, i);
            longitude += offsetY * i;

            const position = {
                latitude: latitude,
                longitude: longitude,
            }

            createVwMarker2D(vw, `ziazag${i}`, NewWaypointImage, position);
        }

        // for (var i = 0; i < numPoints; i++) {
        //     const position = {
        //         latitude: center.latitude + offsetX * Math.pow(-1, i),
        //         longitude: center.longitude + offsetY * i
        //     }
            
        //     createVwMarker2D(vw, `zigzag${i}`, NewWaypointImage, position);
        // }
    }, [])

    return (
        <S.Wrap>
            {/* <S.MissionBtn isOn={pathIndex === 99} onClick={onClickTestBtn}>
                Test
            </S.MissionBtn> */}
            <S.MissionBtn isOn={pathIndex === 1} onClick={() => setPathIndex(1)}>
                path
            </S.MissionBtn>
            <S.MissionBtn isOn={pathIndex === 2} onClick={() => setPathIndex(2)}>
                region
            </S.MissionBtn>
            {/* <S.MissionBtn onClick={onClickSetRoute}>
                mission
            </S.MissionBtn> */}
            {/* <S.MissionBtn onClick={onClickTest2Btn}>생성</S.MissionBtn> */}
            <S.MissionBtn isOn={pathIndex === 0} onClick={onClickCancelBtn}>
                cancel
            </S.MissionBtn>
        </S.Wrap>
    );
}

export default SetMissionWaypoint;
