import { useEffect, useState } from "react";
import styled from "styled-components";
import windDirArrow from "../../../gcs_image/weather/wind_dir.svg";

const Text = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
`
const SpeedImageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scaleY(-1);
`
const DirImage = styled.img`
  transform: rotate(${props=>props.rotate?props.rotate:0}deg);
`
const SpeedBlock = styled.div`
  width: 44px;
  height: 2px;
  margin-top: 2px;
  background-color: #FFFFFF;
`
const EmptySpeedBlock = styled.div`
  width: 44px;
  height: 2px;
  margin-top: 2px;
  background-color: none;
`
const ItemContainer = styled.ul`
  display :flex;
`
const Item = styled.li`
  border-left: 0.2px solid #667069;
  flex-basis: 100%;
  text-align: center;
  &:nth-child(1){
    border-left: none;
  }
`
let dirRotate = 0;

export const getWindDir = ()=>{
  return dirRotate;
}
function WindData({data}){
  const [infos,setInfos] = useState([
    {dir:null, dirRotate:0, speed:0, time: '00'},
    {dir:null, dirRotate:0, speed:0, time: '00'},
    {dir:null, dirRotate:0, speed:0, time: '00'},
    {dir:null, dirRotate:0, speed:0, time: '00'},
    {dir:null, dirRotate:0, speed:0, time: '00'},
    {dir:null, dirRotate:0, speed:0, time: '00'},
  ]);
  useEffect(()=>{
    if(data.length>0){
      const dir = ['북풍','북동풍','동풍','남동풍','남풍','남서풍','서풍','북서풍'];
      let tempInfo = data.map((info)=>{
        let direction;
        if(Number(info.VEC) < 22.5 || Number(info.VEC) > 337.5){
          direction = 0;
          return {dir:dir[direction], dirRotate:direction, speed:Math.round(info.WSD), time:info.fcstTime.substring(0,2)};
        }else{
          direction = parseInt(((Number(info.VEC)/22.5)-1)/2);
        }
        if(direction === NaN) {
          return {dir:'ERR', dirRotate:0, speed:Math.round(info.WSD), time:info.fcstTime.substring(0,2)};
        }
        return {dir:dir[direction+1], dirRotate:direction+1, speed:Math.round(info.WSD), time:info.fcstTime.substring(0,2)};
      });
      setInfos(tempInfo);
    }
  },[data]);

  const buildBlock = (num)=>{
    try{
      let maxspeed = 0;
      for(let i=0;i<infos.length;i++){
        if(maxspeed<infos[i].speed)maxspeed = infos[i].speed 
      }
      const speed = Math.round(Number(num));
      let blocks=[];
      if(speed === 0) return (<></>);
      for(let i=0; i<maxspeed; i++){
        if(i>=num){
          blocks.push(<EmptySpeedBlock key={i}/>);
        }else{
          blocks.push(<SpeedBlock key={i}/>);
        }
      }
      return <>{blocks}</>;
    } catch (e){
        return (
          <></>
        );
    }
  };
  if(data.length > 0){
    dirRotate = data[0].VEC
  }
  return(
    <>
      <ItemContainer>
        {infos.map((info,idx)=>(
          <Item key={idx}>
            <DirImage src={windDirArrow} rotate={(info.dirRotate+180) * 45}/>
            <Text>{info.dir}</Text>
            <Text isWeight={true}>{info.speed}</Text>
            <SpeedImageBox>
              {buildBlock(info.speed)}
            </SpeedImageBox>
          </Item>
        ))}
      </ItemContainer>
      <ItemContainer>
        {infos.map((info,idx)=>(
          <Item key={idx} style={{borderLeft: 'none'}}>
            <Text key={idx}>{info.time}:00</Text>
          </Item>
        ))}
      </ItemContainer>
    </>
      
  )
}
export default WindData;
/* <MainItem>
        <InfoText>풍향</InfoText>
        <InfoContainer>
          {infos.map((info,idx)=>(
            <InfoItem key={idx}>
              <DirImageBox>
                <DirImage src={windDirArrow} rotate={(info.dirRotate) * 45}/>
              </DirImageBox>
              <Text>{info.dir}</Text>
            </InfoItem>
          ))}
        </InfoContainer>
      </MainItem>
      <MainItem>
        <InfoText>풍속 m/s</InfoText>
        <InfoContainer>
          {infos.map((info,idx)=>(
            <InfoItem key={idx}>
              <SpeedImageBox>
                {buildBlock(info.speed)}
              </SpeedImageBox>
              <Text isWeight={true}>{Math.round(info.speed)}m/s</Text>
            </InfoItem>
          ))}
        </InfoContainer>
      </MainItem>
      <InfoContainer>
        {infos.map((info,idx)=>(
          <InfoItem key={idx}>
            <Text>{info.time}시</Text>
          </InfoItem>
        ))}
      </InfoContainer> */