import styled from "styled-components";
import LogoImg from "../../../gcs_image/left_menu/logo.svg";
import viaLogo from "../../../gcs_image/left_menu/via_logo.svg";
import wooriioLogo from "../../../gcs_image/left_menu/woori-io-logo.svg";

const Container = styled.div`
  margin-top: 7vh;
  width: 335px;
`
const LogoWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 40px;
`
const Title = styled.span`
  font-weight: 600;
  font-size: 43px;
  line-height: 30px;
  color: #FFFFFF;
`
const SubTitle = styled.span`
  font-weight: 400;
  font-size: 28px;
  line-height: 52px;
  color: #ffffff;
`
function Logo(){
 
  return(
    <Container>
      <LogoWrapper>
        <img src={wooriioLogo} alt="woori-io-logo" />
        <img src={viaLogo} alt="via-logo" />
      </LogoWrapper>
      <Title>Mine Detection<br/><SubTitle>Ground Control System</SubTitle></Title>
    </Container>
  )
}

export default Logo;