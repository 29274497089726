import { combineReducers } from "redux";
import map from "./reducer/map";
import drone from "./reducer/drone";
import video from "./reducer/video";

const rootReducer = combineReducers({
  map,
  drone,
  video,
});

export default rootReducer;