import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { sendMsg } from "../../../store/reducer/drone";
const Container = styled.div`
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 68.06%);
    border-top: 1px solid rgba(255, 255, 255, 0.36);
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
    backdrop-filter: blur(30px);
    border-radius: 90px;
    padding: 5px 23px 5px 5px;
    display: flex;
    align-items: center;
    margin-right: 23px;
`;
const Title = styled.div`
    background: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(255, 255, 255, 0.19);
    backdrop-filter: blur(52px);
    border-radius: 50%;
    width: 62px;
    font-weight: 500;
    font-size: 13px;
    line-height: 62px;
    text-align: center;
    color: #00e0ff;
`;
const ItemContainer = styled.ul`
    display: flex;
    height: fit-content;
`;
const Item = styled.li`
    border-left: 0.5px solid #606a66;
    font-weight: 400;
    font-size: 16px;
    color: #a2a9a7;
    padding: 0 10px;
    cursor: pointer;
    &:nth-child(1) {
        border-left: none;
    }
`;
function FavoritesMode() {
    const dispatch = useDispatch();
    const { socket, updateSwitch } = useSelector((state) => ({
        socket: state.drone.socket,
        updateSwitch: state.drone.updateSwitch, //소켓데이터 수신 후 업데이트시 리렌더링 하기 위한 스위치
    }));
    /*
  dispatch(sendMsg(message)) -> socket으로 message 전송, 여러대일 경우 focus socket일 것
  */
    return (
        <Container>
            <Title>Mode</Title>
            <ItemContainer>
                <Item
                    onClick={() => {
                        dispatch(sendMsg("03" + "||" + "GUIDED" + "||" + socket.drone_serial_number));
                    }}
                    style={
                        !!socket && socket !== ""
                            ? socket.getDroneStatus().mode === "GUIDED"
                                ? { color: "#FFFFFF" }
                                : { color: "#A2A9A7" }
                            : { color: "#FFFFFF" }
                    }
                >
                    Guided
                </Item>
                <Item
                    onClick={() => {
                        dispatch(sendMsg("03" + "||" + "LOITER" + "||" + socket.drone_serial_number));
                    }}
                    style={
                        !!socket && socket !== ""
                            ? socket.getDroneStatus().mode === "LOITER"
                                ? { color: "#FFFFFF" }
                                : { color: "#A2A9A7" }
                            : { color: "#FFFFFF" }
                    }
                >
                    Loiter
                </Item>
                <Item
                    onClick={() => {
                        dispatch(sendMsg("03" + "||" + "RTL" + "||" + socket.drone_serial_number));
                    }}
                    style={
                        !!socket && socket !== ""
                            ? socket.getDroneStatus().mode === "RTL"
                                ? { color: "#FFFFFF" }
                                : { color: "#A2A9A7" }
                            : { color: "#FFFFFF" }
                    }
                >
                    RTL
                </Item>
            </ItemContainer>
        </Container>
    );
}

export default FavoritesMode;
