import styled from "styled-components";
import { useSelector } from "react-redux";

import setAltitude from "../../../gcs_image/mission_nav/set_altitude.svg";
import setSpeed from "../../../gcs_image/mission_nav/set_speed.svg";
import setWaypoint from "../../../gcs_image/mission_nav/set_waypoint.svg";
import setRoi from "../../../gcs_image/mission_nav/set_roi.svg";
import readMission from "../../../gcs_image/mission_nav/read_mission.svg";
import setAltitudeActive from "../../../gcs_image/mission_nav/set_altitude_active.svg";
import setSpeedActive from "../../../gcs_image/mission_nav/set_speed_active.svg";
import setWaypointActive from "../../../gcs_image/mission_nav/set_waypoint_active.svg";
import setRoiActive from "../../../gcs_image/mission_nav/set_roi_active.svg";
import readMissionActive from "../../../gcs_image/mission_nav/read_mission_active.svg";
import messageImage from "../../../gcs_image/mission_nav/drone_message.svg";

const Container = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 90px;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ItemContainer = styled.ul`
    height: 32%;
    margin-top: 25vh;
    margin-bottom: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Item = styled.li``;
const Image = styled.img`
    display: block;
    cursor: pointer;
`;
function SideBar(props) {
    const missionImage = [setAltitude, setSpeed, setWaypoint, setRoi, readMission];
    const missionImageActive = [setAltitudeActive, setSpeedActive, setWaypointActive, setRoiActive, readMissionActive];
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));

    return (
        <Container>
            <ItemContainer>
                {missionImage.map((image, idx) => (
                    <Item
                        key={idx}
                        onClick={() => {
                            props.setMissionIndex(idx);
                        }}
                    >
                        <Image src={props.missionIndex === idx ? missionImageActive[idx] : image} />
                    </Item>
                ))}
            </ItemContainer>
            <Image
                src={messageImage}
                onClick={() => {
                    if (!!socket && socket !== "") {
                        props.setOpenMessage(!props.openMessage);
                    } else {
                        alert("기체와 연결해주세요.");
                        return;
                    }
                }}
            />
        </Container>
    );
}

export default SideBar;
