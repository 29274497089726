import { useState } from "react";
import styled from "styled-components";
import Logo from "./Left/Logo/Logo";
import SideBar from "./Left/SideBar/SideBar";
import FlightMode from "./Left/FlightMode/FlightMode";
import MissionNav from "./Left/Mission/MissionNav";
import Video360 from "./Left/360Video/Video360";
import MineOption from "./Left/MineOption/MineOption";
import WarningMessage from "./Left/WarningMessage/WarningMessage";
import MesssageBox from "./Left/MessageBox/MesssageBox";
import MineData from "./Left/MineOption/MineData";
import AIPredict from "./Left/AIPredict/AIPredict";
const Container = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 397px;
    /* width: fit-content; */
    height: 100vh;
    top: 0;
    left: 90px;
    background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
    backdrop-filter: blur(30px);
    border-radius: 0px 30px 30px 0px;
    padding: 0 20px 0 35px;
    z-index: 3;
    /* display: flex; */
`;
// const ViewContainer = styled.div`
//   width: 100%;
//   height: 622.27px;
//   top: 136px;
//   overflow:${props=>props.isFullScreen?'visible':'hidden'};
//   position: absolute;
//   transition: 2s;
//   box-sizing: border-box;
// `
function LeftConsole() {
    // const [isModeSelect,setisModeSelect] = useState(false);
    // const {isFullScreen} = useSelector(state=>({
    //   isFullScreen:state.map.isFullScreen,
    // }));
    const [openMessage, setOpenMessage] = useState(false);
    const [missionIndex, setMissionIndex] = useState(0);
    return (
        <>
            <Container>
                <Logo />
                <FlightMode />
                <MissionNav missionIndex={missionIndex} />
                {/* <Video360 /> */}
                <AIPredict />
                <MineOption />
            </Container>
            <SideBar
                openMessage={openMessage}
                setOpenMessage={setOpenMessage}
                missionIndex={missionIndex}
                setMissionIndex={setMissionIndex}
            />
            {/* <WarningMessage /> */}
            {openMessage ? (
                <MesssageBox
                    close={() => {
                        setOpenMessage(false);
                    }}
                />
            ) : null}
        </>
    );
}
{
    /* <CommandConsole /> */
}
{
    /* <FlightMode isModeSelect={isModeSelect} setisModeSelect={setisModeSelect}/>
<ViewContainer style={isModeSelect?{paddingTop:'100vh'}:{paddingTop:'0'}} isFullScreen={isFullScreen}>
  <Mission />
  <Video />
</ViewContainer> */
}

export default LeftConsole;
