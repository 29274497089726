import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from 'moment';

const S = {
    CalendarWrap: styled.div`
        width: 270px;
        /* height: 200px; */
        margin: 0 auto;

        .react-calendar {
            background-color: #3d3d3d;
        }

        .react-calendar__tile {
            color: #fff;
            border-radius: 50%;
        }
        .react-calendar__tile:disabled {
            border-radius: none;
            color: #262626;
            background-color: transparent;
        }

        .react-calendar__month-view__weekdays {
            color: #fff;
            font-size: 14px;
        }
        .react-calendar__month-view__weekdays__weekday abbr {
            text-decoration: none;
        }
        .react-calendar__tile--now {
            background: transparent;
        }
        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus {
            background: #d6d6d6;
        }
        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
            background-color: #e6e6e6;
            color: black;
        }
        .react-calendar__tile--active {
            background: #0d53e7;
            color: white;
        }

        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            background: #0d53e7;
            color: white;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
            color: #757575;
        }
        .react-calendar__navigation button {
            color: #fff;
        }
        .react-calendar__navigation button:disabled,
        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
            background-color: transparent;
        }
        .react-calendar__navigation__label {
            pointer-events: none;
        }
    `,
    BtnWrap: styled.div`
        width: 100%;
        display: flex;
        gap: 5px;
        margin-top: 5px;

        .download-btn {
            width: 100%;
            height: 30px;
            background-color: #0d53e7;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            cursor: pointer;

            p {
                font-weight: 600;
                color: #fff;
            }
        }
        .predict-btn {
            width: 90px;
            height: 30px;
            background-color: #0d53e7;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            cursor: pointer;

            p {
                font-weight: 600;
                color: #fff;
            }
        }

        .cancel-btn {
            width: 90px;
            height: 30px;
            background-color: #3d3d3d;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            cursor: pointer;

            p {
                font-weight: 600;
                color: #fff;
            }
        }
    `,
};

function MineCalendar({selectedDate, setSelectedDate, viewFrame}) {
    // const [selectedDate, setSelectedDate] = useState("");
    // const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
    const today = new Date();

    return (
        <S.CalendarWrap>
            <Calendar
                formatShortWeekday={(locale, date) => ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
                formatDay={(locale, date) => date.toLocaleString("en", { day: "numeric" })}
                tileDisabled={({ date }) => date >= today}
                nextLabel={">"}
                prevLabel={"<"}
                next2Label={null}
                prev2Label={null}
                value={selectedDate}
                onClickDay={(day) => {
                    setSelectedDate(day);
                }}
            />

            <S.BtnWrap>
                <div className="download-btn" onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL2}/media/${viewFrame}/${moment(selectedDate).format("YYYYMMDD")}.csv`)
                }}>
                    <p>Download</p>
                </div>
            </S.BtnWrap>
        </S.CalendarWrap>
    );
}

export default MineCalendar;
