import styled from "styled-components";
import emergencyImage from "../../../gcs_image/drone_controll_button/emergencyImageRed.svg";
import armingImage from "../../../gcs_image/drone_controll_button/arming_motor.svg";
import takeOffImage from "../../../gcs_image/drone_controll_button/take_off.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendMsg } from "../../../store/reducer/drone";
import Toggle from "./Toggle";
import { set_is_active_ai } from "../../../store/reducer/video";
const Container = styled.div`
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 68.06%);
    border-top: 1px solid rgba(255, 255, 255, 0.36);
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
    backdrop-filter: blur(30px);
    border-radius: 90px;
    margin-right: 23px;
    display: flex;
    padding-left: 20px;
`;
const ToggleContainer = styled.div`
    border-right: 0.5px solid #606a66;
    padding: 11px 20px;
    font-weight: 400;
    font-size: 9px;
    color: #c2c7c5;
`;
const ItemContainer = styled.ul`
    display: flex;
    align-items: center;
    height: 100%;
`;
const Item = styled.li`
    padding: 20px 29px;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
`;
const BtmImage = styled.img`
    margin-right: 7px;
`;
function CommandBotton(props) {
    const dispatch = useDispatch();
    const { socket, is_active_ai } = useSelector((state) => ({
        socket: state.drone.socket,
        is_active_ai: state.video.is_active_ai, //AI 감지 ON/OFF
    }));
    return (
        <>
            <Container>
                <ItemContainer>
                    <Item
                        onClick={() => {
                            dispatch(sendMsg("03" + "||" + "RTL" + "||" + socket.drone_serial_number));
                        }}
                        style={{ color: "#EF2020" }}
                    >
                        <BtmImage src={emergencyImage} />
                        Emergency
                    </Item>
                    <Item
                        onClick={() => {
                            dispatch(sendMsg("01" + "||" + socket.drone_serial_number));
                        }}
                    >
                        <BtmImage src={armingImage} />
                        Arming motor
                    </Item>
                    <Item
                        onClick={() => {
                            props.OpenAltMenu();
                        }}
                    >
                        <BtmImage src={takeOffImage} />
                        Take off
                    </Item>
                </ItemContainer>
            </Container>
        </>
    );
}

export default CommandBotton;
