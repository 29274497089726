import React from 'react';
import styled from 'styled-components';

const S = {
    Wrap: styled.div`
        margin-top: 30px;
        
        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 18px;
            color: #fff;
            margin-bottom: 10px;
        }
    `,

    BtnWrap: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 15px;

        .predict-btn {
            width: 50%;
            height: 40px;
            background-color: #0d53e7;
            border: 1px solid gray;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .cancel-btn {
            width: 100%;
            height: 40px;
            /* background-color: #3d3d3d; */
            border: 1px solid gray;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
        }
    `,
    AIPredictInfo: styled.div`
        padding: 6px;
        border: 2px dashed #3d3d3dAA;

        p {
            font-size: 13px;
            color: #fff;
        }
    `,
}

function AIPredict(props) {
    return (
        <S.Wrap>
            <p className="title">AI Prediction</p>
            <S.AIPredictInfo>
                <p>Left click to check the AI prdiction,<br />
                    Right click to remove an AI prediction.
                </p>
            </S.AIPredictInfo>
        </S.Wrap>
    );
}

export default AIPredict;