import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DroneWebSocket } from "../../class/Drone";
import { setSocket, updateData } from "../../store/reducer/drone";
import { AIPredictAPI } from "../../api/mine_apis";
import { calcFromLatLng } from "./Cesium";

function ConnectServer(props) {
    const { vw, map, socket, polygon } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
        polygon: state.map.polygon,
    }));
    let { ws3d } = window;
    let { Cesium } = window;
    const dispatch = useDispatch();

    // 지도 로딩 완료되었을 때 드론 연결
    vw.ws3dInitCallBack = function () {
        connect();
    };

    const connect = useCallback(() => {
        let socket = new DroneWebSocket(`wss://${process.env.REACT_APP_API_URL_WEBSOCKET_SERVER}/3`);

        socket.onopen = () => {
            doSetBase(socket);
            dispatch(setSocket(socket));
            setTimeout(() => {
                // console.log(socket.getDronePosition())
                doCameraMove(socket.getDronePosition());
            }, 1000);
        };

        socket.addEventListener("message", function (event) {
            // updateData() 호출 시 T/F가 변경되며 데이터를 수신받았다는 스위치로 작동
            dispatch(updateData());
        });

        socket.onclose = () => {
            socket.setIsConnect(false);
            clearInterval(socket.getSocketInterval());
        };

        socket.onerror = (error) => {
            socket.setIsConnect(false);
            clearInterval(socket.getSocketInterval());
        };
    }, []);

    // 처음 연결 시 설정
    const doSetBase = (socket) => {
        socket.setIsConnect(true);
        // 내부 저장된 미션 수신용
        socket.send("10");
        // 홈 위치 수신용
        socket.send("99");

        const interval = setInterval(() => {
            // 지속적 통신
            socket.send("");
        }, 500);
        socket.setSocketInterval(interval);

        socket.setMap(map);
        socket.setVw(vw);
    };

    // 연결 시 카메라 이동
    const doCameraMove = (droneStatus) => {
        // 좌표가 (0.0, 0.0)이 아닐 때
        if (droneStatus.longitude !== "0.0" && droneStatus.latitude !== "0.0") {
            let movePosition = new vw.CoordZ(droneStatus.longitude, droneStatus.latitude, 800);
            let cameraPosition = new vw.CameraPosition(movePosition, new vw.Direction(0, -90, 0));
            map.moveTo(cameraPosition);
        }
    };

    ws3d.viewer.screenSpaceEventHandler.setInputAction(function (event) {
        var pickedObject = ws3d.viewer.scene.pick(event.position);
        if (socket.marker.length > 0) {
            socket.marker.map((data) => {
                // Check if the picked object is the marker entity
                if (Cesium.defined(pickedObject) && pickedObject.id === data.marker.id) {
                    socket.drone_serial_number = data.ip;
                    // Perform your desired actions when the marker is clicked
                    // You can access the marker's properties using the 'marker' variable
                }
            });
        }

        polygon.map((poly) => {
            if (Cesium.defined(pickedObject) && pickedObject.id === poly.polygon) {
                displayAIPrediction(poly.prob, poly.position);
            }
        })
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    const displayAIPrediction = useCallback(async (prob, position) => {
        try {
            map.removeObjectById(`AI_polygon`)
            map.removeObjectById(`AI_polyline`)

            const res = await AIPredictAPI(prob);
            const distance_data = {
                front: res.data.data.FrontArea,
                back: res.data.data.BackArea,
                side: res.data.data.SideArea,
            }
            const bounds_points = calcFromLatLng(distance_data, position)

            const NorthPoint = new vw.Coord(bounds_points.north.longitude, bounds_points.north.latitude);
            const EastPoint = new vw.Coord(bounds_points.east.longitude, bounds_points.east.latitude);
            const SouthPoint = new vw.Coord(bounds_points.south.longitude, bounds_points.south.latitude);
            const WestPoint = new vw.Coord(bounds_points.west.longitude, bounds_points.west.latitude);

            const ar = new Array();
            ar.push(NorthPoint);
            ar.push(EastPoint);
            ar.push(SouthPoint);
            ar.push(WestPoint);

            const coordCol = new vw.Collection(ar);
            const polygon = new vw.geom.Polygon(coordCol);
            polygon.setId("AI_polygon");
            // polygon.setFillColor(vw.Color.GOLD);
            polygon.setFillColor(new vw.Color(255, 100, 100, 204));
            polygon.create();
            
            ar.push(NorthPoint);

            const coordCol2 = new vw.Collection(ar);

            // 폴리라인 생성
            const lineString = new vw.geom.LineString(coordCol2);
            lineString.setId("AI_polyline");
            lineString.setFillColor(new vw.Color(255, 255, 255, 204));
            lineString.setWidth(0.5);
            lineString.create();

        } catch (e) {
            console.log(e);
        }
    }, [])

    return;
}

export default ConnectServer;
