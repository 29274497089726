import React, {useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from "react-redux";
import useMineInfo from '../../../hooks/queries/useMineInfo';
import { mineColor, mineColorWithoutPolygon } from './MineColor';
import RealtimeDisplay from './Realtime/RealtimeDisplay';
import HistoryDisplay from './HistoryDisplay';
import waypointMarker from "../../../gcs_image/marker/waypoint.svg";
import newWaypointmarker from "../../../gcs_image/marker/waypoint_marker.svg";
import { createVwMarker2D } from '../Mission/MissionFunctions';
import { AIPredictAPI, getMineDataAPI } from '../../../api/mine_apis';
import { set_grid_polygon, set_mine_coord } from '../../../store/reducer/map';
import { calcFromLatLng, createMineLabel, deleteEntitie } from '../../Function/Cesium';

function MineData({ displayMethod, viewFrame, timeOption, selectedDate, setSelectedDate }) {
    const dispatch = useDispatch();
    const { vw, map, socket, grid_polygon } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
        grid_polygon: state.map.polygon,
    }));
    const [mineLabelList, setMineLabelList] = useState([]);
    let { ws3d } = window;
    let { Cesium } = window;

    const [historyGridData, setHistoryGridData] = useState([]);
    const [realtimeData, setRealtimeData] = useState([]);
    const [realtimeGridData, setRealtimeGridData] = useState([]);

    useEffect(() => {
        // Grid/Coord 변경 시
        if (timeOption === "realtime") {
            overlayInit();
            setRealtimeGridData([]);

            // 이전에 쌓인 데이터가 있으면 좌표 표시
            if (displayMethod === "coord") {
                realtimeData.map((realtime, idx) => {
                    const position = {
                        latitude: realtime.lat,
                        longitude: realtime.lng,
                    }
                    const marker = createVwMarker2D(vw, `realtimeCoordData${idx}`, newWaypointmarker, position);
                    const mineLabel = createMineLabel(position, String(realtime.prob));
                    setMineLabelList((prev) => {
                        let newList = [...prev];
                        newList.push(mineLabel);
                        return newList;
                    })
                    const eventHandler = function (windowPosition, ecefPosition, cartographic, featureInfo) {
                        dispatch(set_mine_coord(position));
                        displayAIPrediction(realtime.prob, realtime)
                    }
                    marker.addEventListener(eventHandler);
                })
            }
            // 이전에 쌓인 데이터가 있으면 격자 표시
            else {
                realtimeData.map((realtime_data, index) => {
                    calcDroneRealtime(realtime_data);
                })
            }
        }
    }, [displayMethod])



    const displayAIPrediction = useCallback(async (prob, position) => {
        try {
            map.removeObjectById(`AI_polygon`)
            map.removeObjectById(`AI_polyline`)

            const res = await AIPredictAPI(prob);
            const distance_data = {
                front: res.data.data.FrontArea,
                back: res.data.data.BackArea,
                side: res.data.data.SideArea,
            }
            const bounds_points = calcFromLatLng(distance_data, position)

            const NorthPoint = new vw.Coord(bounds_points.north.longitude, bounds_points.north.latitude);
            const EastPoint = new vw.Coord(bounds_points.east.longitude, bounds_points.east.latitude);
            const SouthPoint = new vw.Coord(bounds_points.south.longitude, bounds_points.south.latitude);
            const WestPoint = new vw.Coord(bounds_points.west.longitude, bounds_points.west.latitude);

            const ar = new Array();
            ar.push(NorthPoint);
            ar.push(EastPoint);
            ar.push(SouthPoint);
            ar.push(WestPoint);

            const coordCol = new vw.Collection(ar);
            const polygon = new vw.geom.Polygon(coordCol);
            polygon.setId("AI_polygon");
            // polygon.setFillColor(vw.Color.GOLD);
            polygon.setFillColor(new vw.Color(255, 100, 100, 204));
            polygon.create();
            
            ar.push(NorthPoint);

            const coordCol2 = new vw.Collection(ar);

            // 폴리라인 생성
            const lineString = new vw.geom.LineString(coordCol2);
            lineString.setId("AI_polyline");
            lineString.setFillColor(new vw.Color(255, 255, 255, 204));
            lineString.setWidth(0.5);
            lineString.create();

        } catch (e) {
            console.log(e);
        }
    }, [])

    const calcDroneRealtime = useCallback((mine_info) => {
        const coords = calcGridCoords({ latitude: mine_info.lat, longitude: mine_info.lng });

        let count = 0;
        for (let i = 0; i < realtimeGridData.length; i++) {
            if (realtimeGridData[i].coords.a.lat === coords.a.lat && realtimeGridData[i].coords.a.lng === coords.a.lng) {
                // 저장된 list에 평균 재계산
                let newAvg = ((realtimeGridData[i].avg * realtimeGridData[i].count) + mine_info.prob) / (realtimeGridData[i].count + 1);
                setRealtimeGridData(prev => {
                    let newList = [...prev];
                    newList[i].avg = newAvg;
                    newList[i].count += 1;
                    return newList;
                })
                return;
            }
            count++;
        }
        if (count === realtimeGridData.length) {
            setRealtimeGridData(prev => {
                let newList = [...prev];
                newList.push({
                    coords: {
                        a: {
                            lat: coords.a.lat,
                            lng: coords.a.lng,
                        }, c: {
                            lat: coords.c.lat,
                            lng: coords.c.lng,
                        },
                    },
                    avg: mine_info.prob,
                    count: 1
                })
                return newList;
            })
        }
    }, [realtimeGridData])

    useEffect(() => {
        // Realtime/History, Drone/UGV 변경 시
        overlayInit();
        setSelectedDate("");
        setRealtimeData([]);
        setRealtimeGridData([]);
        setHistoryGridData([]);
    }, [timeOption, viewFrame])

    useEffect(() => {
        // history selectedDate 변경 시
        overlayInit();
        setHistoryGridData([]);
    }, [selectedDate])

    const calcDate = useCallback((date) => {
        if (date === "") {
            return null
        }
        var year = date.getFullYear();              //yyyy
        var month = (1 + date.getMonth());          //M
        month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
        var day = date.getDate();                   //d
        day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
        return year + '' + month + '' + day;
    }, [])

    // const { data: historyData } = useMineInfo({
    //     date: calcDate(selectedDate),
    //     frame: viewFrame,
    //     successFn: (data) => {console.log(data)},
    //     errorFn: () => {}
    // })

    const [historyData, setHistoryData] = useState(null);

    useEffect(() => {
        if (!!map) {
            fetchHistoryData();
        }
    }, [selectedDate])

    const fetchHistoryData = useCallback(async () => {
        try {
            if (selectedDate !== "") {
                const res = await getMineDataAPI(calcDate(selectedDate), viewFrame);
                setHistoryData(res);
            }
        } catch (e) {
            console.log("!!! e : ", e)
        }
    }, [selectedDate, viewFrame])

    const overlayInit = useCallback(() => {
        // history 격자 폴리곤 제거
        historyGridData.map((grid_data, index) => {
            map.removeObjectById(`historyGridData${index}`);
        })
        // history 좌표 마커 제거
        if (!!historyData) {
            historyData.data.map((history_mine_data, index) => {
                map.removeObjectById(`historyCoordData${index}`);
            })
        }
        // realtime 격자 폴리곤 제거
        realtimeGridData.map((grid_data, index) => {
            map.removeObjectById(`realtimeGridData${index}`);
        })
        grid_polygon.map((polygon) => {
            deleteEntitie(polygon.polygon);
        })
        dispatch(set_grid_polygon([]));

        // realtime 좌표 마커 제거
        realtimeData.map((realtime_data, index) => {
            map.removeObjectById(`realtimeCoordData${index}`);
        })

        mineLabelList.map((label, idx) => {
            deleteEntitie(label);
        })
        setMineLabelList([]);
        
    }, [historyData, historyGridData, realtimeGridData, realtimeData, grid_polygon, mineLabelList])

    const calcGridCoords = useCallback((position) => {
        const MAP_GPS_DISTANCE_PER_KM_LAT = 0.008983152877;
        const MAP_GPS_DISTANCE_PER_KM_LNG = 0.010967299711;

        const MAP_GPS_DISTANCE_PER_M_LAT = 0.000008983152877;
        const MAP_GPS_DISTANCE_PER_M_LNG = 0.000010967299711;

        const dividedLat = position.latitude / MAP_GPS_DISTANCE_PER_M_LAT;
        const dividedLng = position.longitude / MAP_GPS_DISTANCE_PER_M_LNG;

        const floorLat = Math.floor(dividedLat);
        const floorLng = Math.floor(dividedLng);

        const aLat = floorLat * MAP_GPS_DISTANCE_PER_M_LAT;
        const aLng = floorLng * MAP_GPS_DISTANCE_PER_M_LNG;

        const cLat = aLat + MAP_GPS_DISTANCE_PER_M_LAT;
        const cLng = aLng + MAP_GPS_DISTANCE_PER_M_LNG;

        return {a: {lat: aLat, lng: aLng}, c: {lat: cLat, lng: cLng}}
    }, [])

    const drawPolygon = useCallback((grid_data, index, id) => {
        const pointA = new vw.Coord(grid_data.coords.a.lng, grid_data.coords.a.lat);
        const pointB = new vw.Coord(grid_data.coords.a.lng, grid_data.coords.c.lat);
        const pointC = new vw.Coord(grid_data.coords.c.lng, grid_data.coords.c.lat);
        const pointD = new vw.Coord(grid_data.coords.c.lng, grid_data.coords.a.lat);

        const coordArr = [];
        coordArr.push(pointA);
        coordArr.push(pointB);
        coordArr.push(pointC);
        coordArr.push(pointD);

        const coordCol = new vw.Collection(coordArr);
        const polygon = new vw.geom.PolygonZ(coordCol);
        polygon.setId(`${id}${index}`);
        mineColor(polygon, vw, grid_data.avg);
        // polygon.setFillColor(new vw.Color(255, 0, 0, 30))
        polygon.setOutLineColor(vw.Color.RED);

        polygon.create();
    }, [ws3d, Cesium, grid_polygon])

    const drawPolygonCesium = useCallback((grid_data, index, id) => {
        const polygonCoordinates = Cesium.Cartesian3.fromDegreesArray([
            grid_data.coords.a.lng, grid_data.coords.a.lat,
            grid_data.coords.a.lng, grid_data.coords.c.lat,
            grid_data.coords.c.lng, grid_data.coords.c.lat,
            grid_data.coords.c.lng, grid_data.coords.a.lat
        ]);

        const polygon = ws3d.viewer.entities.add({
            polygon: {
                hierarchy: polygonCoordinates,
                material: new Cesium.ColorMaterialProperty(
                    mineColorWithoutPolygon(grid_data.avg, Cesium),
                    0.8
                ),
                extrudeHeight: 0
            }
        })
        
        let polygon_arr = grid_polygon;
        polygon_arr.push({
            polygon: polygon,
            prob: grid_data.avg,
            position: {
                lat: grid_data.coords.a.lat,
                lng: grid_data.coords.a.lng,
            }
        });

        dispatch(set_grid_polygon(polygon_arr));
    }, [Cesium, ws3d])

    // 오른쪽 마우스 클릭 이벤트 - AI prediction 제거
    ws3d.viewer.screenSpaceEventHandler.setInputAction(function (event) {
        map.removeObjectById("AI_polyline");
        map.removeObjectById("AI_polygon");
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    return (
        <div>
            <RealtimeDisplay
                realtimeGridData={realtimeGridData}
                setRealtimeGridData={setRealtimeGridData}
                viewFrame={viewFrame}
                timeOption={timeOption}
                displayMethod={displayMethod}
                realtimeData={realtimeData}
                setRealtimeData={setRealtimeData}
                calcGridCoords={calcGridCoords}
                setMineLabelList={setMineLabelList}
                overlayInit={overlayInit}
                drawPolygon={drawPolygonCesium}
                calcDroneRealtime={calcDroneRealtime} />
            
            <HistoryDisplay
                historyData={historyData}
                displayMethod={displayMethod}
                overlayInit={overlayInit}
                calcGridCoords={calcGridCoords}
                setMineLabelList={setMineLabelList}
                historyGridData={historyGridData}
                setHistoryGridData={setHistoryGridData}
                drawPolygon={drawPolygonCesium} />
        </div>
    );
}

export default MineData;