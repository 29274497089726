import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
    /* position: absolute; */
    position: relative;
    z-index: 2;
    /* top: 60px; */
    position: absolute;
    bottom: 28px;
    /* left: 526px; */
    background: rgba(0, 0, 0, 0.26);
    box-shadow: inset 4px 1px 30px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    width: 330px;
    /* min-width: 330px; */
    padding: 24px 30px;
    box-sizing: border-box;
    margin-top: auto;
`;
const TextBox = styled.div`
    width: 100%;
    min-height: 30px;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #ef2020;
    box-sizing: border-box;
`;
const ArmStatus = styled(TextBox)`
    background: none;
    box-shadow: none;
`;
function WarningMessage() {
    const { socket, updateSwitch } = useSelector((state) => ({
        socket: state.drone.socket,
        updateSwitch: state.drone.updateSwitch,
    }));
    const [messageIndex, setMessageIndex] = useState(0);
    const [WarningText, setWarningText] = useState("");

    const getNewWaringMessage = () => {
        if (!!socket && socket !== "") {
            const msg = socket.getMessage();
            if (msg.length > 0) {
                for (let i = msg.length - 1; i >= messageIndex; i--) {
                    if (msg[i].severity < 5) {
                        if (messageIndex < i) {
                            setMessageIndex(i);
                            setWarningText(msg[i].message);
                            return;
                        }
                    }
                }
            }
        }
    };
    useEffect(() => {
        getNewWaringMessage();
    }, [socket, updateSwitch, messageIndex]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setWarningText("");
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [messageIndex]);
    return (
        <Container>
            <ArmStatus>{!!socket && socket !== "" && (socket.getDroneStatus().isArm ? "ARMED" : "DISARMED")}</ArmStatus>
            {/* <TextBox style={{textAlign:'center'}}>{socket.getDroneStatus().isArm?'ARMED':'DISARMED'}</TextBox> */}
            <TextBox>{WarningText}</TextBox>
        </Container>
    );
}

export default WarningMessage;
