import { useSelector } from "react-redux";
import styled from "styled-components";
import closeImage from "../../../gcs_image/mission_nav/close.svg"
const Container = styled.div`
  background: linear-gradient(180deg, rgba(122, 146, 132, 0.2) 0%, rgba(122, 146, 132, 0.2) 100%);
  border: 1px solid rgba(255, 255, 255, 0.43);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  /* padding: 22px 27px; */
  width: fit-content;
  position: absolute;
  bottom: 122px;
  left:526px;
`
const ItemContainer = styled.ul`
  min-width: 200px;
  width: fit-content;
  height: 330px;
  margin: 22px 27px;
  overflow-y: scroll;
  padding-right: 23px;
  &::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: #00E0FF;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
  }

`
const Item = styled.li`
  color: ${props=>props.severity<=3?'#EF2020':props.severity==4?'#FDE50F':'#FFFFFF'};
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
`
const Image = styled.img`
  cursor:pointer;
  position: absolute;
  top:3px;
  right:3px;
`
function MesssageBox(props){
  const {socket,updateSwitch} = useSelector(state=>({
    socket: state.drone.socket,
    updateSwitch : state.drone.updateSwitch
  }))
  return(
    <Container>
      <Image src={closeImage} onClick={()=>{props.close()}}/>
      <ItemContainer>
        {socket.getMessage().map((message,idx)=>(
          <Item key={idx} severity={message.severity}>
            {message.message}
          </Item>
        ))}
      </ItemContainer>
    </Container>
  )
}

export default MesssageBox;