import styled from "styled-components";
import { BaseBtn, BaseCancleBtn } from "../../../GlobalStyled";
import clinkGuidedImage from "../../../gcs_image/mission_nav/clickGuide.svg";
import RoiPoint from "../../../gcs_image/marker/roi.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendMsg } from "../../../store/reducer/drone";
import { createMission, createVwMarker2D, testBackMission, testGoMission } from "./MissionFunctions";
import SetWaypoints from "./SetWaypoints";
import { useEffect } from "react";
import SetMissionWaypoint from "./SetMissionWaypoint";
const Container = styled.div`
    margin-top: 21px;
`;
const Start = styled(BaseBtn)`
    font-weight: 500;
    font-size: 20px;
    line-height: 56px;
    color: #ffffff;
    text-align: center;
    margin-top: 14px;
`;
const InputDate = styled.input`
    border: none;
    background: rgba(0, 0, 0, 0.26);
    box-shadow: inset 4px 1px 30px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.36);
`;
const GuiedClick = styled.div`
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(122, 146, 132, 0.2) 0%, rgba(122, 146, 132, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.43);
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.36);
    backdrop-filter: blur(10px);
    border-radius: 3px;
    height: 119px;
    width: 100%;
    box-sizing: border-box;
    background: url(${clinkGuidedImage});
    background-position: center;
    background-repeat: no-repeat;

    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    padding-top: 23px;
`;
const ReadBtn = styled(BaseCancleBtn)`
    font-weight: 500;
    font-size: 20px;
    line-height: 56px;
    color: #ffffff;
    text-align: center;
    margin-top: 14px;
`;

function MissionSetting({ missionIndex, setIsMissionStart, missionData, setMissionDate }) {
    const dispatch = useDispatch();
    const { vw, map, socket } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
    }));

    const removeSettingImage = () => {
        map.removeObjectById(`missionLine2D`);
        for (let i = 0; i < missionData.waypoint.length; i++) {
            map.removeObjectById(`wp${i}`);
        }
    };
    const sendMission = () => {
        if (!!socket && socket !== "") {
            dispatch(sendMsg("10" + "||" + socket.drone_serial_number));
            let mission;
            mission = createMission(missionData);
            // mission = testGoMission(100);
            // mission = testBackMission(100);
            dispatch(sendMsg("11" + "||" + mission + "||" + socket.drone_serial_number));
            dispatch(sendMsg("03" + "||" + "AUTO" + "||" + socket.drone_serial_number));
            setIsMissionStart(true);
            removeSettingImage();
        } else {
            alert("기체와 연결해주세요.");
            return;
        }
    };
    const readMission = () => {
        if (!!socket && socket !== "") {
            dispatch(sendMsg("10" + "||" + socket.drone_serial_number));
            const readData = socket.getMissionData();
            let readSpeed = 0;
            let readWaypoint = [];
            for (let i = 0; i < readData.length; i++) {
                if (readData[i].command == 178) {
                    readSpeed = readData[i].param2;
                }
                if (readData[i].command == 16) {
                    // let alt = (Number(readData[i].param7)+Number(socket.getDroneHomePosition().altitude)) - getHeight(readData[i].param5,readData[i].param6)
                    let alt = Number(readData[i].param7);
                    readWaypoint.push({ latitude: readData[i].param5, longitude: readData[i].param6, altitude: alt });
                }
            }
            // for(let i=0; i<readWaypoint.length; i++){
            //   let point = createVwMarker2D(vw,`wp${i}`,WaypointImage,readWaypoint[i]);
            // }
            // drawLine(vw,readWaypoint);
            removeSettingImage();
            if (readSpeed == 0) {
                setMissionDate({
                    ...missionData,
                    waypoint: readWaypoint,
                });
            } else {
                setMissionDate({
                    ...missionData,
                    waypoint: readWaypoint,
                    speed: readSpeed,
                });
            }
            // dispatch(sendMsg("03" + "AUTO"));
            setIsMissionStart(true);
        } else {
            alert("기체와 연결해주세요.");
            return;
        }
    };
    const setRoi = useEffect(() => {
        let event;
        if (missionIndex === 3) {
            event = (windowPosition, ecefPosition, cartographic) => {
                const position = { latitude: cartographic.latitudeDD, longitude: cartographic.longitudeDD };
                map.removeObjectById(`roi`);

                let point = createVwMarker2D(vw, `roi`, RoiPoint, position);
                setMissionDate({
                    ...missionData,
                    roi: position,
                });
            };
            map.onClick.addEventListener(event);
        }
        return () => {
            map.onClick.removeEventListener(event);
        };
        // },[vw,map,missionData,modifyData,socket]);
    }, [vw, map, missionIndex]);
    const removeRoi = () => {
        map.removeObjectById(`roi`);
        setMissionDate({
            ...missionData,
            roi: 0,
        });
    };
    return (
        <>
            {missionIndex === 0 ? (
                <InputDate
                    // value={missionData.altitude}
                    value={missionData.altitude == 0 ? "" : missionData.altitude}
                    placeholder="Enter elevation"
                    onChange={(e) => {
                        setMissionDate({ ...missionData, altitude: e.target.value });
                    }}
                />
            ) : null}
            {missionIndex === 1 ? (
                <InputDate
                    value={missionData.speed == 0 ? "" : missionData.speed}
                    placeholder="Enter speed"
                    onChange={(e) => {
                        setMissionDate({ ...missionData, speed: e.target.value });
                    }}
                />
            ) : null}
            {missionIndex === 2 ? (
                <GuiedClick>
                    Set up a Waypoint
                    {!!socket && socket !== "" && (
                        <SetMissionWaypoint
                            missionData={missionData}
                            setMissionData={setMissionDate}
                            getDroneHomePosition={socket.getDroneHomePosition()}
                            missionIndex={missionIndex}
                        />
                        // <SetWaypoints
                        //     missionData={missionData}
                        //     setMissionDate={(data) => {
                        //         setMissionDate(data);
                        //     }}
                        //     getDroneHomePosition={() => {
                        //         return socket.getDroneHomePosition();
                        //     }}
                        // />
                    )}
                </GuiedClick>
            ) : null}
            {missionIndex === 3 ? (
                <GuiedClick
                    onClick={() => {
                        removeRoi();
                    }}
                >
                    Set up a Roi
                </GuiedClick>
            ) : null}
            {missionIndex === 4 ? (
                <InputDate
                    value={missionData.reapet == 0 ? "" : missionData.reapet}
                    placeholder="Enter reapet"
                    onChange={(e) => {
                        setMissionDate({ ...missionData, reapet: e.target.value });
                    }}
                />
            ) : null}
            <Start
                onClick={() => {
                    sendMission();
                }}
            >
                Mission Start
            </Start>
            <ReadBtn
                onClick={() => {
                    readMission();
                }}
            >
                Read Mission
            </ReadBtn>
        </>
    );
}

export default MissionSetting;

// const [modifyData,setModifyData] = useState({
//   isModify:false,
//   modifyIndex:0
// })
// const addWaypoint = (position)=>{
//   let newWaypoint = [...missionData.waypoint];
//   newWaypoint.push(position);
//   setMissionDate({
//     ...missionData,
//     waypoint:newWaypoint,
//   })
// };
// const markerClickEvent = (windowPosition, ecefPosition, cartographic, featureInfo) => {
//   if ( featureInfo != null ) {
//     const id = featureInfo.groupId;
//     const pointObj = map.getObjectById( id );
//     pointObj.setImage(WaypointImageModifying);
//     pointObj.create();
//     setModifyData({
//       isModify:true,
//       modifyIndex:Number((pointObj.id).substring(2))
//     });
//   }
// }

// const mapClickEvent = useEffect(()=>{
//   let event;
//   if(missionIndex===2||missionIndex===3){
//     event = (windowPosition, ecefPosition, cartographic) =>{

//       if(missionIndex===2){
//         for(let i=0;i<missionData.waypoint.length;i++){
//           let wpMarker = map.getObjectById(`wp${i}`);
//           wpMarker.addEventListener(markerClickEvent);
//         }
//       }

//       const missionAlt = getHeight(cartographic.latitudeDD,cartographic.longitudeDD) + Number(missionData.altitude) - Number(socket.getDroneHomePosition().altitude);
//       const position = {latitude : cartographic.latitudeDD,longitude : cartographic.longitudeDD,altitude : missionAlt};
//       let point;

//       if(missionIndex===3){
//         map.removeObjectById(`roi`);
//         point = createVwMarker2D(vw,`roi`,RoiPoint,position);
//         setMissionDate({
//           ...missionData,
//           roi:position
//         })
//       }else{
//         if(modifyData.isModify){
//           const markerId = `wp${modifyData.modifyIndex}`
//           map.removeObjectById(markerId);
//           point = createVwMarker2D(vw,markerId,WaypointImage,position);
//           setModifyData({
//             ...modifyData,
//             isModify:false
//           });
//           let newWaypoint = [...missionData.waypoint];
//           newWaypoint[modifyData.modifyIndex] = position;
//           setMissionDate({
//             ...missionData,
//             waypoint:newWaypoint,
//           })
//           point.addEventListener(markerClickEvent)
//         }else{
//           point = createVwMarker2D(vw,`wp${missionData.waypoint.length}`,WaypointImage,position);
//           addWaypoint(position)
//         }
//         point.addEventListener(markerClickEvent)
//       }
//     }
//     map.onClick.addEventListener(event);
//   }

//   return ()=>{
//     map.onClick.removeEventListener(event);
//     for(let i=0;i<missionData.waypoint.length;i++){
//       let wpMarkers = map.getObjectById(`wp${i}`);
//       if(wpMarkers !=undefined) wpMarkers.removeEventListener();
//     }
//   };
// },[missionIndex,vw,map,missionData,modifyData,socket]);
