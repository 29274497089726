import { useSelector } from "react-redux";
import styled from "styled-components";
import StepGraph from "../Prediction/StepGraph";

const Container = styled.div`
  border-top: 0.2px solid rgba(255, 255, 255, 0.25);;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`
const ValueContainer = styled.ul`
  /* min-width: 125px; */
`
const ValueItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ValueName = styled.span`
  font-weight: 300;
  font-size: 13px;
  line-height: 26px;
  color: #FFFFFF;
  margin-right: 5px;
`
const Value = styled.span`  
  min-width: 70px;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #FFFFFF;
  text-align: right;
`
function HydrogenData(){
  const {socket,updateSwitch} = useSelector(state=>({
    socket: state.drone.socket,
    updateSwitch : state.drone.updateSwitch
  }))
  return(
    <>
      <Container>
        <ValueContainer>
          <ValueItem>
            <ValueName>Output(V)</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2_output_voltge}</Value>
          </ValueItem>
          <ValueItem>
            <ValueName>Output(A)</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2_output_current}</Value>
          </ValueItem>
          <ValueItem>
            <ValueName>Battery(V)</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2_battery_voltge}</Value>
          </ValueItem>
          <ValueItem>
            <ValueName>Battery(A)</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2_battery_current}</Value>
          </ValueItem>
        </ValueContainer>
        <ValueContainer>
        <ValueItem>
            <ValueName>파워팩출력(W)</ValueName>
            <Value>{!!socket && socket !== "" && ((socket.getPowerpack().h2_output_current)*(socket.getPowerpack().h2_output_voltge)).toFixed(2)}</Value>
          </ValueItem>
          <ValueItem>
            <ValueName>수소(bar)</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2}</Value>
          </ValueItem>
          <ValueItem>
            <ValueName>안쪽 온도(℃)</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2_temperature_in}</Value>
          </ValueItem>
          <ValueItem>
            <ValueName>에러</ValueName>
            <Value>{!!socket && socket !== "" && socket.getPowerpack().h2_error_code}</Value>
          </ValueItem>
        </ValueContainer>
      </Container>
      <StepGraph step={!!socket && socket !== "" && (socket.getPowerpack().h2==0?0:((socket.getPowerpack().h2)/350)*100)} color={'#FFFFFF'}/>
    </>
  );
}

export default HydrogenData;