import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { set_mine_coord } from '../../../../store/reducer/map';

import { createVwMarker2D, createVwMarker3D } from "../../../Left/Mission/MissionFunctions";
import waypointMarker from "../../../../gcs_image/marker/waypoint.svg";
import newWaypointmarker from "../../../../gcs_image/marker/waypoint_marker.svg";
import { AIPredictAPI } from '../../../../api/mine_apis';
import { calcFromLatLng, createMineLabel, getHeight } from '../../../Function/Cesium';


function RealtimeDrone({displayMethod, calcGridCoords, realtimeData, setRealtimeGridData, realtimeGridData, overlayInit, drawPolygon, calcDroneRealtime, setMineLabelList}) {
    const dispatch = useDispatch();
    const { vw, map } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
    }));

    useEffect(() => {
        if (displayMethod === 'grid') {
            // 이전 폴리곤 제거
            overlayInit();
            realtimeData.map((realtime_data, index) => {
                calcDroneRealtime(realtime_data);
            })
        } else {
            displayRealtimeDataCoord();
        }
    }, [realtimeData])

    useEffect(() => {
        realtimeGridData.map((realtime_data, index) => {
            drawPolygon(realtime_data, index, 'realtimeGridData');
        })
    }, [realtimeGridData])

    const displayRealtimeDataCoord = useCallback(() => {
        if (realtimeData.length > 0) {
            const position = {
                latitude: realtimeData[realtimeData.length - 1].lat,
                longitude: realtimeData[realtimeData.length - 1].lng,
            }
            // const marker = createVwMarker2D(vw, `realtimeCoordData${realtimeData.length - 1}`, newWaypointmarker, position, String(realtimeData[realtimeData.length - 1].prob));
            const marker = createVwMarker2D(vw, `realtimeCoordData${realtimeData.length - 1}`, newWaypointmarker, position);
            const mineLabel = createMineLabel(position, String(realtimeData[realtimeData.length - 1].prob));
            setMineLabelList((prev) => {
                let newList = [...prev];
                newList.push(mineLabel);
                return newList;
            })

            const eventHandler = function (windowPosition, ecefPosition, cartographic, featureInfo) {
                dispatch(set_mine_coord(position));
                displayAIPrediction(realtimeData[realtimeData.length - 1].prob, realtimeData[realtimeData.length - 1])
            }
            marker.addEventListener(eventHandler);
        }
    }, [realtimeData])

    const displayAIPrediction = useCallback(async (prob, position) => {
        try {
            map.removeObjectById(`AI_polygon`)
            map.removeObjectById(`AI_polyline`)

            const res = await AIPredictAPI(prob);
            const distance_data = {
                front: res.data.data.FrontArea,
                back: res.data.data.BackArea,
                side: res.data.data.SideArea,
            }
            const bounds_points = calcFromLatLng(distance_data, position)

            const NorthPoint = new vw.Coord(bounds_points.north.longitude, bounds_points.north.latitude);
            const EastPoint = new vw.Coord(bounds_points.east.longitude, bounds_points.east.latitude);
            const SouthPoint = new vw.Coord(bounds_points.south.longitude, bounds_points.south.latitude);
            const WestPoint = new vw.Coord(bounds_points.west.longitude, bounds_points.west.latitude);

            const ar = new Array();
            ar.push(NorthPoint);
            ar.push(EastPoint);
            ar.push(SouthPoint);
            ar.push(WestPoint);

            const coordCol = new vw.Collection(ar);
            const polygon = new vw.geom.Polygon(coordCol);
            polygon.setId("AI_polygon");
            // polygon.setFillColor(vw.Color.GOLD);
            polygon.setFillColor(new vw.Color(255, 100, 100, 204));
            polygon.create();
            
            ar.push(NorthPoint);

            const coordCol2 = new vw.Collection(ar);

            // 폴리라인 생성
            const lineString = new vw.geom.LineString(coordCol2);
            lineString.setId("AI_polyline");
            lineString.setFillColor(new vw.Color(255, 255, 255, 204));
            lineString.setWidth(0.5);
            lineString.create();

        } catch (e) {
            console.log(e);
        }
    }, [])

    return;
}

export default RealtimeDrone;