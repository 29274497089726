import axios from "axios";

export const getMineDataAPI = (date, frame) => {
    return axios.get(
        `${process.env.REACT_APP_API_URL_MINE_INFO}/${date}/${frame}`
    )
}

export const AIPredictAPI = (prob) => {
    return axios.get(
        `${process.env.REACT_APP_API_URL_AI_PREDICT}/${prob}`
    )
}