import styled from "styled-components";
import windImg from "../../../gcs_image/weather/wind.svg"
import WindData from "./WindData";
const Container = styled.div`
  margin-bottom: 33px;
`
const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 19px;
  color: #FFFFFF;
`
const TitleImage = styled.img`
  margin-right: 7px;
`
const TitleText = styled.span`
  margin-right: 3px;
`
const TitleUnit = styled.span`
  font-size: 14px;
  color: #BBC1BE;
`
function WindInfo({data}){

  return(
    <Container>
      <Title>
        <TitleImage src={windImg} />
        <TitleText>Wind</TitleText>
        <TitleUnit>m/s</TitleUnit>
      </Title>
      <WindData data={data} />
    </Container>
  );
}

export default WindInfo;