import styled from "styled-components";
import rainImg from "../../../gcs_image/weather/rain.svg"
import PrecipitationData from "./PrecipitationData";
const Container = styled.div`

`
const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 19px;
  color: #FFFFFF;
`
const TitleImage = styled.img`
  margin-right: 7px;
`
const TitleText = styled.span`
  margin-right: 3px;
`
const TitleUnit = styled.span`
  font-size: 14px;
  color: #BBC1BE;
`
function PrecipitationInfo({data}){

  return(
    <Container>
      <Title>
        <TitleImage src={rainImg}/>
        <TitleText>Rain</TitleText>
        <TitleUnit>mm</TitleUnit>
      </Title>
      <PrecipitationData data={data}/>
    </Container>
  );
}

export default PrecipitationInfo;