import styled from "styled-components";
import { BaseInput } from "../../../GlobalStyled";

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 30px;
  /* justify-content: center; */
`
const InputContainer = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 39px;
  color: rgba(255, 255, 255, 0.8);
  display:flex;
`
const InputWeight = styled(BaseInput)`
  margin-left: 7px;
  height: 39px;
  width: 107px;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
`
const FlexContainer = styled.div`
  display:flex;
  margin-bottom: 10px;
`
const Text = styled.span`
  display: block;
  width: 45px;

`
function WeightSetting({weight,setWeight}){

  return(
    <Container>
      <FlexContainer>
        <InputContainer style={{marginRight:'15px'}}>
          <Text>습도</Text>
          <InputWeight value={weight.humidity} onChange={(e)=>{setWeight({...weight,humidity:e.target.value})}}/>
        </InputContainer>
        <InputContainer>
          <Text>풍속</Text>
          <InputWeight value={weight.winsSpeed} onChange={(e)=>{setWeight({...weight,winsSpeed:e.target.value})}}/>
        </InputContainer>
      </FlexContainer>
      <InputContainer>
        <Text>경사도</Text>
        <InputWeight value={weight.slope} onChange={(e)=>{setWeight({...weight,slope:e.target.value})}}/>
      </InputContainer>
    </Container>
  )
}

export default WeightSetting;