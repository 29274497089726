import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DroneWebSocket } from "../../class/Drone";
import { setSocket, updateData } from "../../store/reducer/drone";
import { BaseBtn, BaseInput } from "../../GlobalStyled";
import emptyIcon from "../../gcs_image/mission_nav/empty_input.svg";
let { ws3d } = window;
let { Cesium } = window;
const Container = styled.div`
    padding: 66px 127px 77px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.22);
    -webkit-filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
    backdrop-filter: blur(30px);
    border-radius: 10px;

    font-family: "Poppins";
    font-style: normal;
    text-align: center;
    color: #ffffff;
`;
const Title = styled.span`
    font-weight: 400;
    font-size: 42px;
`;
const InputContainer = styled.div`
    width: 100%;
`;
const InputItems = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    font-weight: 300;
    font-size: 20px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;

    margin-top: 20px;
    justify-content: space-between;
    &:nth-child(1) {
        margin-top: 27px;
    }
`;
const InputWIndow = styled(BaseInput)`
    width: 78%;
    font-weight: 300;
    font-size: 18px;
    color: #46514c;
    line-height: 54px;
`;
const Btn = styled(BaseBtn)`
    font-weight: 500;
    font-size: 25px;
    line-height: 56px;
    margin-top: 37px;
`;
const EmptyImage = styled.img`
    position: absolute;
    right: 21px;
    cursor: pointer;
`;
function AddDrone({ setIsconnect }) {
    const { vw, map, socket } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
    }));
    const [droneInfo, setDroneInfo] = useState({ name: "", ip: "", port: "" });
    const [foucsIdx, setFocusIdx] = useState(null);
    const dispatch = useDispatch();

    const droneOption = ["Name", "IP", "Port"];
    const { name, ip, port } = droneInfo;
    const Info = [name, ip, port];

    const change = (e, idx) => {
        if (idx === 0) {
            setDroneInfo({
                ...droneInfo,
                name: e.target.value,
            });
        } else if (idx === 1) {
            setDroneInfo({
                ...droneInfo,
                ip: e.target.value,
            });
        } else if (idx === 2) {
            setDroneInfo({
                ...droneInfo,
                port: e.target.value,
            });
        }
    }; //인풋 onchange용

    const doSetBase = (socket) => {
        socket.setIsConnect(true);
        socket.send(""); //내부 저장된 미션 수신용
        socket.send(""); //홈 위치 수신용
        const interval = setInterval(() => {
            socket.send(""); //지속적 통신
        }, 500);
        socket.setSocketInterval(interval);
        socket.setMap(map);
        socket.setVw(vw);
    }; //처음 연결시 설정

    const doCameraMove = (droneStatus) => {
        if (droneStatus.longitude !== "0.0" && droneStatus.latitude !== "0.0") {
            //좌표가 0,0 이 아닐 때
            let movePosition = new vw.CoordZ(droneStatus.longitude, droneStatus.latitude, 800);
            let cameraPosition = new vw.CameraPosition(movePosition, new vw.Direction(0, -90, 0));
            map.moveTo(cameraPosition);
        }
    }; //연결시 카메라 이동

    const connet = () => {
        //let socket = new DroneWebSocket(`ws://${Info[1]}:${Info[2]}`); //웹소켓 통신 시작
        // let socket = new DroneWebSocket(`ws://localhost:8000/client/` + 3); //웹소켓 통신 시작
        let socket = new DroneWebSocket(`wss://mine-middle.viasoft.ai:80/client/` + 3); //웹소켓 통신 시작
        socket.onopen = () => {
            doSetBase(socket);
            dispatch(setSocket(socket));
            setIsconnect(true);
            setTimeout(() => {
                doCameraMove(socket.getDronePosition());
            }, 1000);
        }; //소켓 오픈시 이벤트
        socket.addEventListener("message", function (event) {
            dispatch(updateData()); //updateData() 호출시 T/F 가 변경되며 데이터를 수신받았다는 스위치로 작동
        }); //소켓 onmessage 이벤트 추가
        socket.onclose = () => {
            socket.setIsConnect(false);
            clearInterval(socket.getSocketInterval());
        }; //소켓 종료시 이벤트
        socket.onerror = (error) => {
            socket.setIsConnect(false);
            clearInterval(socket.getSocketInterval());
        }; //소켓 에러시 이벤트
    };

    ws3d.viewer.screenSpaceEventHandler.setInputAction(function (event) {
        var pickedObject = ws3d.viewer.scene.pick(event.position);
        if (socket.marker.length > 0) {
            socket.marker.map((data) => {
                // Check if the picked object is the marker entity
                if (Cesium.defined(pickedObject) && pickedObject.id === data.marker) {
                    socket.drone_serial_number = data.ip;
                    // Perform your desired actions when the marker is clicked
                    // You can access the marker's properties using the 'marker' variable
                }
            });
        }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    return (
        <Container>
            <Title>Connect drone</Title>
            <InputContainer>
                {droneOption.map((option, idx) => (
                    <InputItems key={option}>
                        {option}
                        <InputWIndow
                            onFocus={() => {
                                setFocusIdx(idx);
                            }}
                            value={Info[idx]}
                            onChange={(e) => {
                                change(e, idx);
                            }}
                            placeholder={"Enter " + option}
                        />
                        {foucsIdx === idx ? (
                            <EmptyImage
                                src={emptyIcon}
                                onClick={() => {
                                    change({ target: { value: "" } }, idx);
                                }}
                            />
                        ) : null}
                    </InputItems>
                ))}
            </InputContainer>
            <Btn
                onClick={() => {
                    connet();
                }}
            >
                Start
            </Btn>
        </Container>
    );
}

export default AddDrone;
