import "./Global.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Index from "./pages/index";
import Map from "./components/Function/Map";

function App() {
    const [loading, setLoading] = useState(true);
    const { map } = useSelector((state) => ({
        map: state.map.map,
    }));

    useEffect(() => {
        if (map) {
            setLoading(false);
        }
    }, [map]);

    return (
        <>
            <Map />
            {!loading && <Index />}
        </>
    );
}

export default App;
