import styled from "styled-components";
import FavoritesMode from "./Bottom/Mode/FavoritesMode";
import CommandBotton from "./Bottom/Button/CommandBotton";
import { BaseBtn, BaseInput, BaseCancleBtn } from "../GlobalStyled";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendMsg } from "../store/reducer/drone";
import { useSelector } from "react-redux";

const Container = styled.div`
    position: absolute;
    bottom: 28px;
    left: 526px;
    display: flex;
`;
const CommandContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 66px 127px 77px;
    box-sizing: border-box;

    background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
    backdrop-filter: blur(30px);
    border-radius: 10px;

    font-family: "Poppins";
    font-style: normal;
    text-align: center;
    color: #ffffff;
`;
const Title = styled.span`
    font-weight: 400;
    font-size: 42px;
    color: #ffffff;
`;
const InputContainer = styled.div`
    width: 100%;
`;
const InputItems = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    font-weight: 300;
    font-size: 20px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;

    margin-top: 20px;
    justify-content: space-between;
    &:nth-child(1) {
        margin-top: 27px;
    }
`;
const InputWIndow = styled(BaseInput)`
    width: 78%;
    font-weight: 300;
    font-size: 18px;
    color: #ffffff;
    line-height: 54px;
`;
const BtnContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CancleBtn = styled(BaseCancleBtn)`
    width: 45%;
    font-weight: 500;
    font-size: 25px;
    line-height: 56px;
    margin-top: 37px;
`;
const Btn = styled(BaseBtn)`
    width: 45%;
    font-weight: 500;
    font-size: 25px;
    line-height: 56px;
    margin-top: 37px;
`;
function BottomConsole() {
    const dispatch = useDispatch();
    const [openAltMenu, setOpenAltMenu] = useState(false);
    const [takeOffAlt, setTakeOffAlt] = useState(0);
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));
    return (
        <>
            <Container>
                <CommandBotton
                    OpenAltMenu={() => {
                        setOpenAltMenu(true);
                    }}
                />
                <FavoritesMode />
            </Container>
            {openAltMenu ? (
                <CommandContainer>
                    <Title>Input Altitude</Title>
                    <InputContainer>
                        <InputItems>
                            Altitude
                            <InputWIndow
                                value={takeOffAlt}
                                onChange={(e) => {
                                    setTakeOffAlt(e.target.value);
                                }}
                            />
                        </InputItems>
                    </InputContainer>
                    <BtnContainer>
                        <CancleBtn
                            onClick={() => {
                                setOpenAltMenu(false);
                            }}
                        >
                            Cancle
                        </CancleBtn>
                        <Btn
                            onClick={() => {
                                dispatch(sendMsg("02" + "||" + takeOffAlt + "||" + socket.drone_serial_number));
                                setOpenAltMenu(false);
                            }}
                        >
                            Take off
                        </Btn>
                    </BtnContainer>
                </CommandContainer>
            ) : null}
        </>
    );
}

export default BottomConsole;
