import { getHeight } from "../../Function/Cesium";
import { doLand, doRTL, setROI, setSpeed, setWaypoint, setWaypoint_AddDelay, takeOff } from "./MissionObject";
import image from "../../../gcs_image/marker/waypoint.svg";

export const createMission = (missionData) => {
    let missions = [];
    if (missionData.waypoint.length > 0) {
        let copyArr = [...missionData.waypoint].reverse();
        if (missionData.speed == 0) missions.push(setSpeed(0, 3));
        else missions.push(setSpeed(0, missionData.speed));
        // if(missionData.roi!=0){
        //   missions.push(setROI(missionData.roi.latitude,missionData.roi.longitude,missionData.roi.altitude))
        // }
        if (missionData.reapet == 0) {
            for (let i = 0; i < missionData.waypoint.length; i++) {
                missions.push(
                    setWaypoint(
                        missionData.waypoint[i].latitude,
                        missionData.waypoint[i].longitude,
                        missionData.waypoint[i].altitude
                    )
                );
            }
        } else {
            for (let j = 0; j < missionData.reapet; j++) {
                for (let i = 0; i < missionData.waypoint.length; i++) {
                    missions.push(
                        setWaypoint(
                            missionData.waypoint[i].latitude,
                            missionData.waypoint[i].longitude,
                            missionData.waypoint[i].altitude
                        )
                    );
                }
                for (let i = 0; i < copyArr.length; i++) {
                    missions.push(setWaypoint(copyArr[i].latitude, copyArr[i].longitude, copyArr[i].altitude));
                }
            }
        }
    }
    return JSON.stringify(missions);
};

export const createVwMarker3D = (vw, id, image, position, home_alt) => {
    let alt_calc = Number(home_alt) + Number(position.altitude) - getHeight(position.latitude, position.longitude);
    let ptCoord = new vw.CoordZ(position.longitude, position.latitude, alt_calc);
    let pt = new vw.geom.PointZ(ptCoord);
    pt.setId(id);
    pt.setImage(image);
    pt.create();
    return pt;
};

export const createVwMarker2D = (vw, id, image, position, label = "") => {
    let ptCoord = new vw.Coord(position.longitude, position.latitude);
    let pt = new vw.geom.Point(ptCoord);
    pt.setId(id);
    pt.setImage(image);
    if (label !== "") {
        pt.setName(label)
    }
    pt.create();
    return pt;
};

export const drawLine = (vw, datas, id) => {
    let ar = [];
    for (let i = 0; i < datas.length; i++) {
        let point = new vw.Coord(datas[i].longitude, datas[i].latitude);
        ar.push(point);
    }
    if (ar.length > 1) {
        let coord = new vw.Collection(ar);
        let line = new vw.geom.LineString(coord);
        line.setId(id);
        line.setFillColor(new vw.Color(0, 171, 148, 255));
        // line.setFillColor(vw.Color.GREEN)
        line.setWidth(1);
        line.create();
        return line;
    }
};

export const testGoMission = (vw, map, points, home) => {
    // const points = [
    //     {latitude : 35.809855, longitude : 127.161174},//100
    //     {latitude : 35.809889, longitude : 127.162687},//100
    //     {latitude : 35.806702, longitude : 127.163398},//100
    //     {latitude : 35.803845, longitude : 127.164823},//100
    //     {latitude : 35.802707, longitude : 127.165979},//100
    //     {latitude : 35.8019, longitude : 127.1685},//100
    //     {latitude : 35.8002, longitude : 127.1683},//100
    // ];
    let missions = [];
    for (let i = 0; i < points.length; i++) {
        map.removeObjectById(`wp${i}`);
        missions.push(setWaypoint(points[i].latitude, points[i].longitude, points[i].altitude));
        createVwMarker3D(vw, `wp${i}`, image, points[i], getHeight(home.latitude, home.longitude));
    }
    return [JSON.stringify(missions), points];
};

export const testBackMission = (vw, map, points, home) => {
    // const points = [
    //   {latitude : 35.809855,longitude : 127.161174},
    //   {latitude : 35.809889,longitude : 127.162687},
    //   {latitude : 35.806702,longitude : 127.163398},
    //   {latitude : 35.803845,longitude : 127.164823},
    //   {latitude : 35.802215,longitude : 127.162814},
    //   {latitude : 35.800628,longitude : 127.160851},
    // ];
    let missions = [];
    let reverse = [...points].reverse();
    let position = home;
    position.altitude = reverse[reverse.length - 1].altitude;
    reverse.push(position);
    for (let i = 0; i < reverse.length; i++) {
        map.removeObjectById(`wp${i}`);
        missions.push(setWaypoint(reverse[i].latitude, reverse[i].longitude, reverse[i].altitude));
        createVwMarker3D(vw, `wp${i}`, image, reverse[i], getHeight(home.latitude, home.longitude));
    }
    missions.push(doLand());
    return [JSON.stringify(missions), reverse];
};

export const testWarningMission = (vw, map, droneLocation, points, alt, home) => {
    let missions = [];
    let nearPoint = {
        distance: get_distance_metres(droneLocation, points[0]),
        point: { latitude: points[0].latitude, longitude: points[0].longitude },
    };
    for (let i = 1; i < points.length; i++) {
        let distance = get_distance_metres(droneLocation, points[i]);
        if (nearPoint.distance > distance) {
            nearPoint = {
                distance: distance,
                point: points[i],
            };
        }
    }

    missions.push(setWaypoint_AddDelay(5, nearPoint.point.latitude, nearPoint.point.longitude, alt));
    missions.push(doLand());
    map.removeObjectById(`safe`);
    // createVwMarker2D(vw,'safe',image,{latitude: nearPoint.point.latitude,longitude : nearPoint.point.longitude})
    nearPoint.point.altitude = alt;
    createVwMarker3D(vw, `safe`, image, nearPoint.point, getHeight(home.latitude, home.longitude));
    return [JSON.stringify(missions), [nearPoint.point]];
};

export const get_distance_metres = (location1, location2) => {
    let dlat = location2.latitude - location1.latitude;
    let dlong = location2.longitude - location1.longitude;
    return Math.sqrt(dlat * dlat + dlong * dlong) * 1.113195e5;
};
