// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import styled from "styled-components";
import { BaseBtn } from "../../../GlobalStyled";
import fireImage from "../../../gcs_image/prediction/fire_start_button.svg"
import gridImage from "../../../gcs_image/marker/fire_point.svg"
import { useState } from "react";
import PredictionConsole from "./PredictionConsole";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createVwMarker2D } from "../../Left/Mission/MissionFunctions";
import { drawLine } from "../../Left/Mission/MissionFunctions";
import { getSlope, sortPointsClockwise, getExpenssionPoint, checkWindDir } from "./PredictionFunction";
import { getPredictAPI, sendWeightAPI } from "../../../api/prediction_apis";
import TimeScroll from "./TimeScroll";
// import PredictionPointSetting from "../PredictionPointSetting";
// import TimeScroll from "../TimeScroll";
// import gridhome from "../../../image/homeMarker.svg";
// import gridpoint from "../../../image/marker.svg";
// import hull from "hull.js";
const Container = styled.div`
  margin-top: 24px;
  width: 100%;
`
const Btn = styled(BaseBtn)`
  display:flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 67px;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  background: ${props=>props.step===0?'linear-gradient(180deg, #FF2828 0%, #B00000 100%)':'linear-gradient(180deg, #1059F2 0%, #003FC2 100%)'};
  /* background: linear-gradient(180deg, #1059F2 0%, #003FC2 100%); */
  /* background: linear-gradient(180deg, #FF2828 0%, #B00000 100%);; */
  &:active {
    background: ${props=>props.step===0?'linear-gradient(180deg, #F75656 0%, #CD1A1A 100%)':'linear-gradient(180deg, #4480FF 0%, #1959DF 100%)'};
    /* background: linear-gradient(180deg, #4480FF 0%, #1959DF 100%); */
    /* background: linear-gradient(180deg, #F75656 0%, #CD1A1A 100%); */
  }
`
const BtnImage = styled.img`
  margin-left: 3px;
`
// const PredictionStart = styled.div`
//   width: 100%;
//   height: 56px;
//   background: #000000;
//   border-radius: 5px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #FFFFFF;
//   cursor: pointer;
//   box-sizing: border-box;
//   &:hover{
//     color: #C3FFE2;
//     border: 1px solid #C3FFE2;
//   }
// `
// const PredictionSetting = styled.div`
//   width: 100%;
//   height: 195px;
//   background: #000000;
//   border-radius: 5px;
// `
// const SettingHeader = styled.div`
//   width: 352px;
//   height: 46px;
//   border-bottom: 1px solid rgba(255, 255, 255, 0.3);
//   box-sizing: border-box;
//   margin: auto;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   text-align: center;
//   line-height: 46px;
//   color: #C3FFE2;
// `
// const SettingBody = styled.div`
//   height: 88px;
//   width: 100%;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   color: #FFFFFF;
//   white-space: pre-line;
// `
// const TextToCentor = styled.div`
//   margin-top: 14px;
// `
// const SettingBtn = styled.div`
//   width: 352px;
//   height: 40px;
//   border: 1px solid #B7B7B7;
//   border-radius: 3px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   color: #FFFFFF;
//   display: flex;
//   align-items:center;
//   justify-content: center;
//   box-sizing: border-box;
//   margin: auto;
//   cursor: pointer;
//   &:hover{
//     border: 1px solid #C3FFE2;
//     color: #C3FFE2;
//   }
// `
function Prediction({data}){
  const {vw,map} = useSelector(state=>({
    vw : state.map.vw,
    map : state.map.map,
  }));
  const [step,setStep] = useState(0);
  const [gridPoints,setGridPoints] = useState([]);
  const [predictionPoints,setPredictionPoints] = useState([]);
  const [weight,setWeight] = useState({
    humidity:0,
    winsSpeed:0,
    slope:0,
  })
  const btnText = ['Fire prediction start','Check up'];

  const clickEvent = useEffect(()=>{
    let event;
    if(step===1){
      event = (windowPosition, ecefPosition, cartographic) =>{
        let newGridPoints = [...gridPoints];
        const position = {latitude: cartographic.latitudeDD,longitude: cartographic.longitudeDD};
        newGridPoints.push(position);
        setGridPoints(newGridPoints)
        createVwMarker2D(vw,`grid${gridPoints.length}`,gridImage,position);
        if(newGridPoints.length>2){
          let linePoints = [...newGridPoints];
          linePoints = sortPointsClockwise(linePoints);
          linePoints.push(linePoints[0]);
          map.removeObjectById(`gridLine`);
          drawLine(vw,linePoints,'gridLine');
        }
      }
      map.onClick.addEventListener(event);
    }
    return()=>{
      map.onClick.removeEventListener(event);
    }
  },[step,gridPoints,vw,map]);

  const prediction = useEffect(()=>{
    if(step===3){
      const getdata = async()=>{
        let points = [];
        let temp = [];
        for(let i=0; i<gridPoints.length; i++){
          points.push([gridPoints[i].longitude,gridPoints[i].latitude])
        }
        
        for(let i=0; i<6;i++){
          let slopes = await getSlope(points,data[i].VEC)
          let predic = await getPredictAPI(data[i].REH,data[i].WSD,slopes[0],slopes[2],slopes[3],slopes[1],i+1);
          points = getExpenssionPoint(points,predic,checkWindDir(data[i].VEC),i);
          temp.push(points);
        }
        setPredictionPoints(temp)
        // setIsLoading(false)
      }
      // setIsLoading(true)
      getdata();
    }
  },[step,gridPoints,vw,map]);
  
  const deleteImage = ()=>{
    for(let i=0; i<gridPoints.length; i++){
      map.removeObjectById(`grid${i}`);
    }
    map.removeObjectById(`gridLine`);
  }
  const nextStep = async()=>{
    if(step===3) {
      setStep(0);
      deleteImage();
      setGridPoints([]);
      setPredictionPoints([]);
    }else if(step===2){
      let result = await sendWeightAPI(weight.humidity,weight.winsSpeed,weight.slope);
      setStep(step+1);
    }else if(step===1){
      if(gridPoints.length>2){
        setStep(step+1)
      }
    }
    else setStep(step+1);
  }
  return(
    <>
      {step===3?
        <TimeScroll predictionPoints={predictionPoints}/>
      :null}
      <Container>
        {step > 0? 
          <PredictionConsole step={step} weight={weight} setWeight={setWeight}/>:null}
        <Btn
          step={step}
          onClick={()=>{nextStep()}}
        >
          {step===0?btnText[0]:btnText[1]}
          {step===0?<BtnImage src={fireImage}/>:null}
        </Btn>
      </Container>
    </>
  );
}

export default Prediction;

// const [stepText,setStepText] = useState([
  //   {body:'지도 위 산불 확산 예측 범위 구역을 그려주세요',btn:'그리드 설정 완료'},
  //   {body:'사용자가 직접 확산 가중치 범위를 설정해\n',body2:'보다 정확한 정보를 얻으세요',btn:'예측 가중치 점수 설정 (필수)'},
  // ]);
  // const [gridPoints,setGridPoints] = useState([]);



  // const drawLine = (coords)=>{
  //   let ar=[];
  //   let hullCoords = sortPointsClockwise(coords);
  //   for(let i=0; i<hullCoords.length; i++){
  //     let point = new vw.Coord(hullCoords[i][0],hullCoords[i][1]);
  //     ar.push(point);
  //   }
  //   let point = new vw.Coord(hullCoords[0][0],hullCoords[0][1]);
  //   ar.push(point);
  //   let coord = new vw.Collection(ar);
  //   let line = new vw.geom.LineString(coord);
  //   line.setId(`gridOutLine`);
  //   line.setFillColor(vw.Color.WHITE);
  //   line.setWidth(1);
  //   line.create();
  //   ar=[];
  // }

  // useEffect(()=>{
  //   let event;
  //   if(step===1){
  //     let temp=[];
  //     event = (windowPosition, ecefPosition, cartographic) =>{
  //       temp.push([cartographic.longitudeDD, cartographic.latitudeDD])
  //       let ptCoord = new vw.Coord(cartographic.longitudeDD, cartographic.latitudeDD);
  //       let pt = new vw.geom.Point(ptCoord);
  //       pt.setId(`grid${temp.length-1}`);
  //       if(temp.length===1){
  //         pt.setImage(gridhome);
  //       }else{
  //         pt.setImage(gridpoint);
  //       }
  //       pt.create();
  //       if(temp.length>2){
  //         map.removeObjectById(`gridOutLine`);
  //         drawLine(temp);
  //       }
  //       setGridPoints(temp);
  //     }
  //     map.onClick.addEventListener(event);
  //   }
  //   return ()=>{
  //     map.onClick.removeEventListener(event);
  //   };
  // },[step,map])

      {/* {step===0?
        <PredictionStart onClick={()=>{setStep(1)}}>
          산불 위험 예측 시작
        </PredictionStart>
      :null}
      {step===1||step===2?
        <PredictionSetting>
          <SettingHeader>
            SETP 0{step}
          </SettingHeader>
          <SettingBody>
            {stepText[step-1].body}
            {stepText[step-1].body2?<TextToCentor>{stepText[step-1].body2}</TextToCentor>:null}
          </SettingBody>
          <SettingBtn onClick={()=>{if(gridPoints.length>2)setStep(step+1)}}>
            {stepText[step-1].btn}
          </SettingBtn>
        </PredictionSetting>
      :null}
      {step===3?
        <PredictionPointSetting setStep={(num)=>{setStep(num)}}/>
      :null}
      {step===4?
        <TimeScroll setStep={(num)=>{setStep(num)}} gridPoints={gridPoints} data={data} setGridPoints={setGridPoints}/>
      :null} */}