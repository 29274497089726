import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
// import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(rootReducer);

// const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    {/* <QueryClientProvider client={queryClient}> */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <App />
    {/* </QueryClientProvider> */}
  </Provider>
);
