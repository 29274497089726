import { create3DModels, createLabel, createLineToGround, createMarker, createModel, deleteEntitie, deleteMaker, getHeight, positionCamera, positionModel, removeModel } from "../components/Function/Cesium";
import droneIMG from "../gcs_image/marker/new_drone_marker.svg";
// import droneIMG from "../gcs_image/marker/drone.svg";
import ugvIMG from "../gcs_image/marker/new_ugv_marker.svg";
// import ugvIMG from "../gcs_image/marker/ugv-icon.svg";
import drone3DModel from "../gcs_image/3DModel/CesiumDrone.glb";


export class DroneWebSocket extends WebSocket {
    constructor(url) {
        super(url);
        this.addEventListener("message", this.onmessage); //onmessage에 add를 함으로써 덮어씌워지는것이 아닌 추가 되도록
    }

    drones = [];

    drone_mine_info = {};
    ugv_mine_info = {};

    droneName = "";
    isConnect = false;
    interval;
    drone_serial_number = "";
    isInit = false;
    homePosition = {
        latitude: "",
        longitude: "",
        altitude: "",
    };

    vw;
    map;
    marker = [];

    message = [];
    entitie;

    battery = {
        current: "0",
        voltage: "0",
    }; //드론 배터리 관련
    powerpack = {
        h2: 0,
        h2_output_voltge: 0,
        h2_output_current: 0,
        h2_battery_voltge: 0,
        h2_battery_current: 0,
        h2_error_code: 0,
        h2_temperature_in: 0,
    }; //수소팩 관련
    dronePosition = {
        latitude: "0.0",
        longitude: "0.0",
        altitude: "0",
    }; //드론 좌표 관련
    gps = {
        satellite: "0",
        hdop: "0",
        fix: "0",
    }; //gps 관련
    efk = {
        efk: "0",
        efk_ok: false,
    }; //efk 데이터 관련
    droneStatus = {
        distanceHome: "0",
        yaw: "0",
        airSpeed: "0",
        groundSpeed: "0",
        isArm: "false",
        mode: "",
        status: "",
        wp_dist: "0",
    }; //기타 드론 상태 관련
    onerror = (error) => {
        //에러 발생 시
        this.isConnect = false;
        clearInterval(this.interval);
    };
    onopen = () => {
        //소켓 오픈 시
        this.isConnect = true;
        this.send("");
        this.interval = setInterval(() => {
            if (this.drones.length > 0) {
                this.drones.map((data) => {
                    this.send("");
                });
            }
        }, 500);
    };
    onclose = () => {
        //소켓 연결 종료 시
        this.isConnect = false;
        clearInterval(this.interval);
    };
    onmessage = async (event) => {
        //메세지 수신 시
        const data = `${event.data}`; //수신받은 데이터
        let socketNumber = data.substring(0, 2);
        let socketString = data.substring(2, data.length);
        let drones = [];
        let index;
   
        // if (this.drones.length > 0) {
        //     this.createDroneMarker(this.drones);
        // }

        if (socketNumber === "00") {
            let droneDatas = JSON.parse(socketString);
            droneDatas.drones.map((data) => {
                let drone = {
                    drone_serial_number: data.data.drone_serial_num,
                    dronePort: data.data.drone_port,
                    battery: {
                        current: data.data.battery_current,
                        voltage: data.data.battery_voltage,
                    },
                    dronePosition: {
                        latitude: data.data.lat,
                        longitude: data.data.lng,
                        altitude: data.data.alt,
                    },
                    gps: {
                        satellite: data.data.gps_num_sat, //수신하는 전체 위성 갯수
                        hdop: data.data.gps_hdop, //수신률
                        fix: data.data.gps_fix, //수신하는 위성 갯수
                    },
                    droneStatus: {
                        distanceHome: data.data.distance_home, //home까지 거리
                        yaw: data.data.yaw,
                        airSpeed: data.data.air_speed,
                        groundSpeed: data.data.ground_speed,
                        isArm: data.data.is_arm,
                        mode: data.data.mode,
                        status: data.data.system_status, //UNINIT,BOOT,CALIBRATING,STANDBY,ACTIVE,CRITICAL,EMERGENCY,POWEROFF,FLIGHT_TERMINATION 중 1
                        wp_dist: data.data.wp_dist, //다음 목적지 까지 거리
                    },
                    efk: {
                        efk: data.data.ekf, //ekf 5개 중 최댓값
                        efk_ok: data.data.ekf_ok,
                    },
                    // powerpack: {
                    //     h2: data.data.h2.toFixed(2),
                    //     h2_output_voltge: data.data.h2_output_voltge.toFixed(2),
                    //     h2_output_current: data.data.h2_output_current.toFixed(2),
                    //     h2_battery_voltge: data.data.h2_battery_voltge.toFixed(2),
                    //     h2_battery_current: data.data.h2_battery_current.toFixed(2),
                    //     h2_error_code: data.data.h2_error_code,
                    //     h2_temperature_in: data.data.h2_temperature_in.toFixed(2),
                    // }, //수소 파워팩 데이터들
                    message: data.text,
                
                    homePosition: {
                        latitude: 0,
                        longitude: 0,
                        altitude: 0,
                    },
                    is_ugv: data.data.is_ugv,
                };

                if (this.drone_serial_number === "") {
                    this.drone_serial_number = data.data.drone_serial_num;
                }

                if (data.data.drone_home_position == "None") {
                    drone.homePosition.latitude = 0;
                    drone.homePosition.longitude = 0;
                    drone.homePosition.altitude = 0;
                } else {
                    let altitude = getHeight(data.data.drone_home_position.latitude, data.data.drone_home_position.longitude);
                    drone.homePosition.latitude = data.data.drone_home_position.latitude;
                    drone.homePosition.longitude = data.data.drone_home_position.longitude;
                    drone.homePosition.altitude = altitude;
                }
                
                if (data.mission_data !== "None") {
                    drone.missionData = JSON.parse(data.data.mission_data)
                }

                if (data.data.mine_info.lat !== '' && data.data.mine_info.lng !== '' && data.data.mine_info.prob !== '') {
                    if (data.data.is_ugv) {
                        this.ugv_mine_info = data.data.mine_info;
                    } else {
                        this.drone_mine_info = data.data.mine_info;
                    }
                }

                drones.push(drone);
            });
            this.removePrevMarker(this.drones, drones);
            this.drones = drones;
            this.createDroneMarker(drones);
        } else if (socketNumber === "10") {
            let parse_data = data.split("||");
            let ip = parse_data[parse_data.length - 1];

            let newStr = data.replace("||", "");
            newStr = newStr.replace("||", "");
            newStr = newStr.replace(ip, "");
            socketString = newStr.substring(2, data.length);

            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === ip);

            this.drones[idx].missionData = JSON.parse(socketString);
        } else if (socketNumber === "99") {
            let parse_data = data.split("||");
            let ip = parse_data[parse_data.length - 1];
            let newStr = data.replace("||", "");
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === ip);

            newStr = newStr.replace(ip, "");
            socketString = newStr.substring(2, data.length);

            //홈포지션 저장 및 해당 위치의 절대고도 값 체크
            if (socketString == "None") {
                this.send("99||" + ip); 

                this.drones[idx].homePosition = {
                    ...this.drones[idx].homePosition,
                    latitude: 0,
                    longitude: 0,
                    altitude: 0,
                };
            } else {
                const arr = socketString.split(",");
                index = arr[0].indexOf("=");
                let latitude = arr[0].substring(index + 1, arr[0].length);

                index = arr[1].indexOf("=");
                let longitude = arr[1].substring(index + 1, arr[1].length);

                let altitude = getHeight(latitude, longitude);

                this.drones[idx].homePosition = {
                    ...this.drones[idx].homePosition,
                    latitude: latitude,
                    longitude: longitude,
                    altitude: altitude,
                };
            }
        }
    };

    async removePrevMarker(prev, crnt) {
        let tmp = prev;
        crnt.map((crntDrone) => {
            const idx = prev.findIndex((obj) => obj.drone_serial_number === crntDrone.drone_serial_number);

            if (idx >= 0) {
                tmp.splice(idx, 1);
            }
        });

        if (tmp.length > 0) {
            tmp.map((tmpDrone) => {
                let idx = this.marker.findIndex((obj) => obj.ip === tmpDrone.drone_serial_number);
                removeModel(this.marker[idx].marker);
            })
        }
    }

    async createDroneMarker(drones) {
        //지도상에 드론 위치 표시
        let markerList = [];
        let marker = null;
        let markerLabel = null;
        let remove_marker = this.marker;
        let remove_event = false;
        
        if (drones.length !== this.marker.length) {
            remove_event = true
        } 
        // await Promise.resolve();
        drones.map((data) => {
            if (data.dronePosition.latitude !== 0 && data.dronePosition.longitude !== 0 && data.homePosition !== undefined) {
                let markerData = { ip: "", marker: "", markerLabel: "", entitie: "" };
                let altitude = getHeight(data.dronePosition.latitude, data.dronePosition.longitude);
                let terrian = Number(data.homePosition.altitude) - Number(altitude) + Number(data.dronePosition.altitude); //홈 고도 - 지도상 해당위치 절대 고도 + 드론 고도 ->

                let terrianAltitude = Number(data.homePosition.altitude) + Number(data.dronePosition.altitude);

                let idx = this.marker.findIndex((obj) => obj.ip === data.drone_serial_number);
                // Promise.resolve().then(()=>{
                    if(idx >= 0) {
                        if (data.is_ugv) {
                            if (this.marker.length > 0) {
                                let position = {
                                    lat: data.dronePosition.latitude,
                                    lng: data.dronePosition.longitude,
                                    // alt: data.dronePosition.altitude
                                    alt: getHeight(data.dronePosition.latitude, data.dronePosition.longitude)
                                }
                                if (this.marker[idx].ip !== "" && this.marker[idx].marker === "") {
                                    marker = createModel(data.drone_serial_number, position, true);
                                    markerData["marker"] = marker;
                                } else {
                                    positionModel(this.marker[idx].marker, position, Number(data.droneStatus.yaw));
                                    markerData["marker"] = this.marker[idx].marker;
                                }
                            }
                            // marker = createMarker(data.dronePosition.longitude, data.dronePosition.latitude, terrianAltitude, -1 * Number(data.droneStatus.yaw), ugvIMG, this.marker[idx].marker);
                            // markerData["marker"] = marker;
                        } else {
                            // marker = createMarker(data.dronePosition.longitude, data.dronePosition.latitude, terrianAltitude, -1 * Number(data.droneStatus.yaw), droneIMG, this.marker[idx].marker);
                            markerData["marker"] = marker;
                            if (this.marker.length > 0) {
                                let position = {
                                    lat: data.dronePosition.latitude,
                                    lng: data.dronePosition.longitude,
                                    alt: terrianAltitude,
                                }
                                if (this.marker[idx].ip !== "" && this.marker[idx].marker === "") {
                                    marker = createModel(data.drone_serial_number, position);
                                    markerData["marker"] = marker;
                                } else {
                                    positionModel(this.marker[idx].marker, position, Number(data.droneStatus.yaw));
                                    markerData["marker"] = this.marker[idx].marker;
                                }
                                let temp = createLineToGround(data.dronePosition.latitude, data.dronePosition.longitude, terrian, this.marker[idx].entitie); //드론 이미지 부터 지면까지 잇는 선
                                markerData["entitie"] = temp;
                            }
                        }
                        remove_marker.splice(idx, 1)
                    }

                    markerData["ip"] = data.drone_serial_number;
                    markerList.push(markerData);
            
                /*
            this.entitie = 지도상의 드론 이미지 부터 지면까지 선
            this.marker = 지도상의 드론 이미지
            this.markerLabel = 드론 위 텍스트
            지도상에 객체 생성시 위 데이터를 같이 넣어주는 이유 : 해당값을 지도에서 삭제하고 재생성
            */
            }
        });
        
        if (remove_event === true) {
            remove_marker.map((data) => {
                deleteMaker(data.entitie)
                deleteMaker(data.marker)
                // deleteMaker(data.markerLabel)
            })
           
        }

        this.marker = markerList;
    }

    //getter
    getMessage() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].message;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.message;
        }
    }

    getEkf() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].efk;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.efk;
        }
    }
    getDroneStatus() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].droneStatus;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.droneStatus;
        }
    }

    getDronePosition() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].dronePosition;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.dronePosition;
        }
    }
    getGps() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].gps;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.gps;
        }
    }

    getBattery() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].battery;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.battery;
        }
    }

    getPowerpack() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].powerpack;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.powerpack;
        }
    }

    getFlightMode() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].flightMode;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.flightMode;
        }
    }

    getIsConnect() {
        return this.isConnect;
    }

    getDroneHomePosition() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].homePosition;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.homePosition;
        }
    }

    getTerrainAltitude() {
        let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
        if (idx >= 0 && this.drones[idx].homePosition != undefined) {
            return (
                Number(this.drones[idx].dronePosition.altitude) + Number(this.drones[idx].homePosition.altitude)
            ).toFixed(2);
        } else {
            return (Number(this.dronePosition.altitude) + Number(this.homePosition.altitude)).toFixed(2);
        }
    }
    getMissionData() {
        try {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].missionData;
            } else {
                throw Error();
            }
        } catch (e) {
            return this.missionData;
        }
    }

    getSocketInterval() {
        return this.interval;
    }
    getIsUGV() {
        if (this.drones.length > 0) {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            if (idx >= 0) {
                return this.drones[idx].is_ugv;
            } else {
                return null;
            }
        }
    }

    //setter
    setMap(map) {
        this.map = map;
    }
    setVw(vw) {
        this.vw = vw;
    }
    setIsConnect(bool) {
        this.isConnect = bool;
    }
    setSocketInterval(interval) {
        this.interval = interval;
    }
    setMissionData(missionData) {
        if (this.drones.length > 0) {
            let idx = this.drones.findIndex((obj) => obj.drone_serial_number === this.drone_serial_number);
            this.drones[idx].missionData = missionData;
        } else {
            this.missionData = missionData;
        }
        this.missionData = missionData;
    }
}
