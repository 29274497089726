import styled from "styled-components";
import { useState, useEffect } from "react";
import MissionStart from "./MissionStart";
import MissionSetting from "./MissionSetting";
const Container = styled.div`
    margin-top: 21px;
`;

function MissionNav(props) {
    const [isMissionStart, setIsMissionStart] = useState(false);
    const [missionData, setMissionDate] = useState({
        altitude: 0,
        speed: 0,
        waypoint: [],
        bounds: [],
        roi: 0,
        reapet: 0,
    });
    return (
        <Container>
            {isMissionStart ? (
                <MissionStart
                    setIsMissionStart={(bool) => {
                        setIsMissionStart(bool);
                    }}
                    missionData={missionData}
                    setMissionDate={(data) => {
                        setMissionDate(data);
                    }}
                />
            ) : (
                <MissionSetting
                    missionIndex={props.missionIndex}
                    setIsMissionStart={(bool) => {
                        setIsMissionStart(bool);
                    }}
                    missionData={missionData}
                    setMissionDate={(data) => {
                        setMissionDate(data);
                    }}
                />
            )}
        </Container>
    );
}

export default MissionNav;
