import styled from "styled-components";
import HydrogenData from "./HydrogenData";
import TitleImg from '../../../gcs_image/PowerPackTitleImage.svg'
const Container = styled.div`
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(49, 58, 91, 0) -5.93%, rgba(33, 39, 61, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29947));
  backdrop-filter: blur(30px);
  border-radius: 10px;
  padding: 37px;
  width: fit-content;
`
const Title = styled.div`
  font-weight: 500;
  font-size: 21px;
  line-height: 43px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
`
const TitleImage = styled.img`
  margin-right: 7px;
`
const TitleText = styled.span`

`
function PowerpackData(){

  return(
    <Container>
      <Title>
        <TitleImage src ={TitleImg} />
        <TitleText>Hydrogen powerpack data</TitleText>
      </Title>
      <HydrogenData />
    </Container>
  );
}

export default PowerpackData;