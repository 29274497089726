import styled from "styled-components";
import VideoFrame from "./VideoFrame";
import { useState } from "react";

const Container = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    margin-top: 5vh;
`;
const ItemContianer = styled.ul`
    /* border-bottom: 0.2px solid #BBC0BF; */
    border-bottom: ${(props) => (props.isFullScreen ? "none" : "0.2px solid #BBC0BF")};
    position: ${(props) => (props.isFullScreen ? "absolute" : "none")};
    top: ${(props) => (props.isFullScreen ? "4%" : "none")};
    left: ${(props) => (props.isFullScreen ? "40vw" : "none")};
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    z-index: 7;
`;
const Item = styled.li`
    min-width: 72px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #26302c;
    border: 0.3px solid rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #9ca09f;
    cursor: pointer;
`;
function Video360() {
    const [selectView, setSelectView] = useState(2);
    const ViewList = ["Foward", "Down", "360°"];
    const [isFullScreen, setIsFullScreen] = useState(false);
    return (
        <Container>
            <ItemContianer isFullScreen={isFullScreen}>
                {ViewList.map((view, idx) => (
                    <Item
                        key={view}
                        style={idx === selectView ? { background: "#FFFFFF", color: "#000000" } : null}
                        onClick={() => {
                            setSelectView(idx);
                        }}
                    >
                        {view}
                    </Item>
                ))}
            </ItemContianer>
            Live video
            {/* <VideoFrame selectView={selectView} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} /> */}
        </Container>
    );
}

export default Video360;
