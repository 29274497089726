import styled from "styled-components";

export const BaseBtn = styled.div`
    background: linear-gradient(180deg, #1059F2 0%, #003FC2 100%);
    border-radius: 3px;
    cursor: pointer;
    &:active {
        background: linear-gradient(180deg, #4480FF 0%, #1959DF 100%);
    }
`
export const BaseCancleBtn = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    cursor: pointer;
    &:active {
        border: 1px solid rgba(255, 255, 255, 0.16);
    }
`

export const BaseInput = styled.input`
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #47524D;
    border-radius: 3px;
    box-sizing: border-box;
    padding-left: 20px;
`