import Logo from "../Left/Logo/Logo";
import { mineColorWithoutPolygon } from "../Left/MineOption/MineColor";
import drone3DMarker from "../../gcs_image/3DModel/CesiumDrone.glb";
import drone3DMarker2 from "../../gcs_image/3DModel/3d_drone.glb";
import ugv3DMarker from "../../gcs_image/3DModel/GroundVehicle.glb";

let { ws3d } = window;
let { Cesium } = window;

let init3DMatrix = {};

/**
 * 죄표를 통한 해당 지면의 높이를 반환
 * 지도상에서 해당 좌표가 랜더링 되어있지 않을 경우 부정확한 값이 들어올 수 있음
 * @param {*} lat
 * @param {*} lng
 * @returns {number}
 */
export const getHeight = (lat, lng) => {
    let height = ws3d.viewer.scene.globe.getHeight(Cesium.Cartographic.fromDegrees(lng, lat));
    return Number(height);
};

/**
 * 미션 비행중 경로를 그리는 함수
 * positions는 {latitude: ,longitude:,altitude :} 의 객체
 * alt 는 드론의 홈포지션의 고도
 * alt 와 position의 고도를 더한값을 포인트의 고도로 주어야 정확한 위치에 표시됨
 * @param {*} positions
 * @param {*} alt
 * @returns {object}
 */
export const createMissionLine = (positions, alt) => {
    /* 
  alt 와 position의 고도를 더한값을 포인트의 고도로 주어야 정확한 위치에 표시됨
  객체 생성시 cesium 객체들은 절대고도를 넣어주기 때문에 상대고도인 positions에 드론의 홈포지션 고도인 alt를 더해줘야 원하는 고도가 나옴
  */
    let linePosition = [];
    for (let i = 0; i < positions.length; i++) {
        linePosition.push(positions[i].longitude);
        linePosition.push(positions[i].latitude);
        linePosition.push(Number(positions[i].altitude) + Number(alt));
    }
    const missionLine = ws3d.viewer.entities.add({
        polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(linePosition),
            width: 5,
            material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromBytes(0, 171, 148),
                dashLength: 10.0
            })
        },
    });
    return missionLine;
};
// line.setFillColor(new vw.Color(0, 171, 148, 255));

// const dash = ws3d.viewer.entities.add({
//         polyline: {
//             positions: positions3,
//             width: 20.0,
//             followSurface: true,
//             material: new Cesium.PolylineDashMaterialProperty({
//                 color: Cesium.Color.WHITE.withAlpha(0.7),
//                 dashLength: 30.0
//             }),
//             // material: new Cesium.PolylineOutlineMaterialProperty({
//             //     color: Cesium.Color.WHITE.withAlpha(0.5),
//             // }),
//             // material: Cesium.Color.RED.withAlpha(0.5)
//         },
//     });

/**
 *
 * @param {*} lat
 * @param {*} lng
 * @param {*} alt
 * @param {*} entitie
 * @returns
 */
export const createLineToGround = (lat, lng, alt, entitie) => {
    const Line = ws3d.viewer.entities.add({
        polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights([lng, lat, getHeight(lat, lng) + Number(alt), lng, lat, 0]),
            width: 1,
        },
    });
    deleteEntitie(entitie);
    return Line;
};

export const createMarker = (lng, lat, alt, degree, img, entitie) => {
    let height = getHeight(lat, lng);
    if (Number(alt) <= height + 1) {
        alt = height + 1;
    }
    const position = Cesium.Cartesian3.fromDegrees(lng, lat, alt);
    const entity = ws3d.viewer.entities.add({
        position: position,
        billboard: {
            image: img,
            eyeOffset: new Cesium.Cartesian3(0, 0, 0),
            alignedAxis: Cesium.Cartesian3.UNIT_Z,
            rotation: Cesium.Math.toRadians(degree),
        },
    });
    deleteEntitie(entitie);
    return entity;
};

export const create3DModels = (lng, lat, alt, degree, entitie) => {
    // let height = getHeight(lat, lng);
    // if (Number(alt) <= height + 1) {
    //     alt = height + 1;
    // }

    // const id = "droneMarker";
    // const point = new this.vw.CoordZ(lng, lat, alt);
    // const options = { scale: 1, minimumPixelSize: 1 };
    // const modelz = new this.vw.geom.ModelZ(id, drone3DMarker, point, options);
    // modelz.create();
    // deleteEntitie(entitie);
    const position = Cesium.Cartesian3.fromDegrees(lng, lat, 200);
    const heading = Cesium.Math.toRadians(degree);
    const pitch = 0;
    const roll = 0;
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(position, heading, pitch, roll);
    const entity = ws3d.viewer.entities.add({
        name: 'drone',
        position: position,
        orientation: orientation,
        model: {
            uri: drone3DMarker,
            // uri: model,
            scale: 10.0,
            minimumPixelSize: 128
        },
    });
    // ws3d.viewer.trackedEntity = entity;
    // deleteEntitie(entitie);
    return entity;
}

export const createModel = (serialNumber, position, is_ugv = false) => {
    // const location = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, 45);
    const location = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, position.alt);
    const modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(location);
    const modelUrl = is_ugv ? ugv3DMarker : drone3DMarker;
    const model = ws3d.viewer.scene.primitives.add(Cesium.Model.fromGltf({
        id: serialNumber,
        url: modelUrl,
        scale: 1.0,
        modelMatrix: modelMatrix,
        minimumPixelSize: 10,
    }))

    init3DMatrix = modelMatrix;

    // console.log(model)
    // ws3d.viewer.trackedEntity = model;
    return model;
}

export const removeModel = (model) => {
    ws3d.viewer.scene.primitives.remove(model);
}

export const degreesToRadians = (val) => {
    return val * Math.PI / 180;
}

export const positionModel = (model, position, yaw) => {
    /////////////////////////////////////////////////////////////////////////
    // TRANSLATION
    /////////////////////////////////////////////////////////////////////////

    const location = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, position.alt);
    let translationMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(location);
    
    // 자세 기울어지는 문제 수정용 행렬값 변경
    translationMatrix[0] = init3DMatrix[0]
    translationMatrix[1] = init3DMatrix[1]
    translationMatrix[2] = init3DMatrix[2]
    translationMatrix[3] = init3DMatrix[3]
    translationMatrix[4] = init3DMatrix[4]
    translationMatrix[5] = init3DMatrix[5]
    translationMatrix[6] = init3DMatrix[6]
    translationMatrix[7] = init3DMatrix[7]
    translationMatrix[8] = init3DMatrix[8]
    translationMatrix[9] = init3DMatrix[9]
    translationMatrix[10] = init3DMatrix[10]
    translationMatrix[11] = init3DMatrix[11]

    /////////////////////////////////////////////////////////////////////////
    // ROTATION
    /////////////////////////////////////////////////////////////////////////

    const calcYaw = yaw - 90 < 0 ? yaw - 90 + 360 : yaw - 90;
    const rotationMatrix = Cesium.Matrix3.fromRotationZ(Cesium.Math.toRadians(360 - calcYaw));

    /////////////////////////////////////////////////////////////////////////
    // CREATE MATRIX
    /////////////////////////////////////////////////////////////////////////

    const modelMatrix = Cesium.Matrix4.multiply(
        translationMatrix,
        Cesium.Matrix4.fromRotationTranslation(rotationMatrix),
        new Cesium.Matrix4()
    );

    model.modelMatrix = modelMatrix;
}

export const positionCamera = function(model) {
    // Zoom to model
    var center = Cesium.Matrix4.multiplyByPoint(model.modelMatrix, model.boundingSphere.center, new Cesium.Cartesian3());
    var transform = Cesium.Transforms.eastNorthUpToFixedFrame(center);
    var camera = ws3d.viewer.scene.camera;
    camera.transform = transform;
    var controller = ws3d.viewer.scene.screenSpaceCameraController;
    var r = 2.0 * Math.max(model.boundingSphere.radius, camera.frustum.near);
    controller.minimumZoomDistance = r * 0.5;
    camera.lookAt(new Cesium.Cartesian3(r, r, r), Cesium.Cartesian3.ZERO, Cesium.Cartesian3.UNIT_Z);
};


export const deleteMaker = (entitie) => {
    deleteEntitie(entitie);
};

export const createLabel = (marker, text, entitie) => {
    var entity = ws3d.viewer.entities.add({
        position: marker.position,
        label: {
            text: text,
            font: "14pt Poppin",
            fillColor: Cesium.Color.BLACK,
            // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            // outlineWidth: 2,
            // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -30),
        },
    });
    entity.label.position = marker.position;
    deleteEntitie(entitie);
    return entity;
};

export const createMineLabel = (location, text) => {
    const position = Cesium.Cartesian3.fromDegrees(location.longitude, location.latitude, getHeight(location.latitude, location.longitude));
    var entity = ws3d.viewer.entities.add({
        position: position,
        label: {
            text: text,
            font: "18pt Poppin",
            // fillColor: Cesium.Color.BLACK,
            fillColor: mineColorWithoutPolygon(text, Cesium),
            // style: Cesium.LabelStyle.FILL_AND_OUTLINE, 
            // outlineWidth: 2,
            // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -40),
        },
    });
    entity.label.position = Cesium.Cartesian3.fromDegrees(location.longitude, location.latitude);
    return entity;
}

export const deleteEntitie = (entitie) => {
    ws3d.viewer.entities.remove(entitie);
};

export const calcFromLatLng = (distance_data, position) => {
    const earth_r = 6378137.0;

    const NorthPosition = {
        latitude: position.lat + (distance_data.front / (earth_r * Math.PI / 180)),
        longitude: position.lng
    }

    const eastOffset = distance_data.side / (earth_r * Math.PI / 180);

    const EastPosition = {
        latitude: position.lat,
        longitude: position.lng + (eastOffset / Math.cos(position.lat * Math.PI / 180))
    }

    const SouthPosition = {
        latitude: position.lat + (distance_data.back * (-1)) / (earth_r * Math.PI / 180),
        longitude: position.lng
    }

    const WestPosition = {
        latitude: position.lat,
        longitude: position.lng + (eastOffset * (-1) / Math.cos(position.lat * Math.PI / 180))
    }

    return {
        north: NorthPosition,
        east: EastPosition,
        west: WestPosition,
        south: SouthPosition,
    }
}