export const mineColor = (polygon, vw, avg) => {
    if (0 <= avg && avg < 10) {
        return polygon.setFillColor(new vw.Color(255, 229, 229, 204));
    } else if (10 <= avg && avg < 20) {
        return polygon.setFillColor(new vw.Color(255, 204, 204, 204));
    } else if (20 <= avg && avg < 30) {
        return polygon.setFillColor(new vw.Color(255, 178, 178, 204));
    } else if (30 <= avg && avg < 40) {
        return polygon.setFillColor(new vw.Color(255, 153, 153, 204));
    } else if (40 <= avg && avg < 50) {
        return polygon.setFillColor(new vw.Color(255, 128, 128, 204));
    } else if (50 <= avg && avg < 60) {
        return polygon.setFillColor(new vw.Color(255, 102, 102, 204));
    } else if (60 <= avg && avg < 70) {
        return polygon.setFillColor(new vw.Color(255, 77, 77, 204));
    } else if (70 <= avg && avg < 80) {
        return polygon.setFillColor(new vw.Color(255, 51, 51, 204));
    } else if (80 <= avg && avg < 90) {
        return polygon.setFillColor(new vw.Color(215, 0, 0, 204));
    } else {
        return polygon.setFillColor(new vw.Color(163, 0, 0, 204));
    }
}

export const mineColorWithoutPolygon = (avg, Cesium) => {
    if (0 <= avg && avg < 10) {
        return Cesium.Color.fromBytes(255, 229, 229)
    } else if (10 <= avg && avg < 20) {
        return Cesium.Color.fromBytes(255, 204, 204)
    } else if (20 <= avg && avg < 30) {
        return Cesium.Color.fromBytes(255, 178, 178)
    } else if (30 <= avg && avg < 40) {
        return Cesium.Color.fromBytes(255, 153, 153)
    } else if (40 <= avg && avg < 50) {
        return Cesium.Color.fromBytes(255, 128, 128)
    } else if (50 <= avg && avg < 60) {
        return Cesium.Color.fromBytes(255, 102, 102)
    } else if (60 <= avg && avg < 70) {
        return Cesium.Color.fromBytes(255, 77, 77)
    } else if (70 <= avg && avg < 80) {
        return Cesium.Color.fromBytes(255, 51, 51)
    } else if (80 <= avg && avg < 90) {
        return Cesium.Color.fromBytes(215, 0, 0)
    } else {
        return Cesium.Color.fromBytes(163, 0, 0)
    }
}