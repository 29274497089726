import { useEffect, useState, useCallback } from "react";
import { BaseBtn, BaseCancleBtn } from "../../../GlobalStyled";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { createVwMarker3D } from "./MissionFunctions";
import WaypointImage from "../../../gcs_image/marker/waypoint.svg";
import newWaypointImage from "../../../gcs_image/waypointMarker/newWaypointMarker.svg";
import { createMissionLine, deleteEntitie, getHeight } from "../../Function/Cesium";

import Waypoint1 from "../../../gcs_image/waypointMarker/waypoint_1.svg";
import Waypoint2 from "../../../gcs_image/waypointMarker/waypoint_2.svg";
import Waypoint3 from "../../../gcs_image/waypointMarker/waypoint_3.svg";
import Waypoint4 from "../../../gcs_image/waypointMarker/waypoint_4.svg";
import Waypoint5 from "../../../gcs_image/waypointMarker/waypoint_5.svg";
import Waypoint6 from "../../../gcs_image/waypointMarker/waypoint_6.svg";
import Waypoint7 from "../../../gcs_image/waypointMarker/waypoint_7.svg";
import Waypoint8 from "../../../gcs_image/waypointMarker/waypoint_8.svg";
import Waypoint9 from "../../../gcs_image/waypointMarker/waypoint_9.svg";
import Waypoint10 from "../../../gcs_image/waypointMarker/waypoint_10.svg";
import Waypoint11 from "../../../gcs_image/waypointMarker/waypoint_11.svg";
import Waypoint12 from "../../../gcs_image/waypointMarker/waypoint_12.svg";
import Waypoint13 from "../../../gcs_image/waypointMarker/waypoint_13.svg";
import Waypoint14 from "../../../gcs_image/waypointMarker/waypoint_14.svg";
import Waypoint15 from "../../../gcs_image/waypointMarker/waypoint_15.svg";
import Waypoint16 from "../../../gcs_image/waypointMarker/waypoint_16.svg";
import Waypoint17 from "../../../gcs_image/waypointMarker/waypoint_17.svg";
import Waypoint18 from "../../../gcs_image/waypointMarker/waypoint_18.svg";
import Waypoint19 from "../../../gcs_image/waypointMarker/waypoint_19.svg";
import Waypoint20 from "../../../gcs_image/waypointMarker/waypoint_20.svg";

const Container = styled.div``;
const GuideConsole = styled.div`
    box-sizing: border-box;
    /* background: linear-gradient(180deg, rgba(122, 146, 132, 0.2) 0%, rgba(122, 146, 132, 0.2) 100%); */
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.36); */
    /* backdrop-filter: blur(10px); */
    border-radius: 3px;
    font-weight: 500;
    font-size: 20px;
    line-height: 59px;
    text-align: center;
    color: #ffffff;
`;
const BtnContainer = styled.div`
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
`;
const PathBtn = styled(BaseBtn)`
    width: 48%;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    line-height: 56px;
    text-align: center;
`;
const CancleBtn = styled(BaseCancleBtn)`
    width: 48%;
    font-weight: 500;
    font-size: 20px;
    line-height: 56px;
    text-align: center;
    color: rgba(255, 255, 255, 0.68);
`;
function MissionStart(props) {
    const { vw, map, socket } = useSelector((state) => ({
        vw: state.map.vw,
        map: state.map.map,
        socket: state.drone.socket,
    }));
    const [is3DPath, setIs3DPath] = useState(false);
    const [flyPath, setFlyPath] = useState();

    const endMission = () => {
        socket.send("03" + "||" + "GUIDED" + "||" + socket.drone_serial_number);
        map.removeObjectById(`roi`);
        for (let i = 0; i < props.missionData.waypoint.length; i++) {
            map.removeObjectById(`3Dwp${i}`);
            map.removeObjectById(`3Dpath${i}`);
        }
        map.removeObjectById("boundsLine2D");
        for (let i = 0; i < props.missionData.bounds.length; i++) {
            map.removeObjectById(`bound${i}`);
        }
        deleteEntitie(flyPath);
        props.setMissionDate({
            ...props.missionData,
            waypoint: [],
            bounds: [],
            Roi: 0,
        });
        props.setIsMissionStart(false);
    };

    const draw3DPath = (position, id) => {
        let alt = socket.getDroneHomePosition().altitude;
        let height = Number(alt) + Number(position.altitude);
        let coord = new vw.CoordZ(position.longitude, position.latitude, height);
        let circlez = new vw.geom.CircleZ(coord, 1);
        circlez.setId(id);
        circlez.setFillColor(vw.Color.WHITE);
        circlez.setExtrudeHeight(5);
        circlez.create();
    };

    const create3DMissionPoint = useEffect(() => {
        for (let i = 0; i < props.missionData.waypoint.length; i++) {
            let markerImage = null;
            if (props.missionData.bounds.length === 0) {
                markerImage = calcWaypointMarker(i);
            } else {
                markerImage = newWaypointImage;
            }
            createVwMarker3D(
                vw,
                `3Dwp${i}`,
                markerImage,
                // WaypointImage,
                props.missionData.waypoint[i],
                socket.getDroneHomePosition().altitude
            );
        }
        return () => {
            for (let i = 0; i < props.missionData.waypoint.length; i++) {
                map.removeObjectById(`3Dwp${i}`);
            }
        };
    }, [props.missionData, socket, map]);

    const calcWaypointMarker = useCallback((num = "") => {
        let index;
        if (num === "") {
            index = props.missionData.waypoint.length;
        } else {
            index = num;
        }
        if (index === 0) {
            return Waypoint1;
        } else if (index === 1) {
            return Waypoint2;
        } else if (index === 2) {
            return Waypoint3;
        } else if (index === 3) {
            return Waypoint4;
        } else if (index === 4) {
            return Waypoint5;
        } else if (index === 5) {
            return Waypoint6;
        } else if (index === 6) {
            return Waypoint7;
        } else if (index === 7) {
            return Waypoint8;
        } else if (index === 8) {
            return Waypoint9;
        } else if (index === 9) {
            return Waypoint10;
        } else if (index === 10) {
            return Waypoint11;
        } else if (index === 11) {
            return Waypoint12;
        } else if (index === 12) {
            return Waypoint13;
        } else if (index === 13) {
            return Waypoint14;
        } else if (index === 14) {
            return Waypoint15;
        } else if (index === 15) {
            return Waypoint16;
        } else if (index === 16) {
            return Waypoint17;
        } else if (index === 17) {
            return Waypoint18;
        } else if (index === 18) {
            return Waypoint19;
        } else if (index === 19) {
            return Waypoint20;
        }
    }, [props.missionData])

    const create3DFlyPath = useEffect(() => {
        let linePositions = [...props.missionData.waypoint];
        linePositions.unshift(socket.getDronePosition());

        let missionLineEntity = createMissionLine(linePositions, socket.getDroneHomePosition().altitude);
        setFlyPath(missionLineEntity);
        /*
      let linePositions = [...msg];
      linePositions.unshift(setWaypoint(socket.getDronePosition().latitude,socket.getDronePosition().longitude,socket.getDronePosition().altitude))

      let missionLineEntity = createMissionLine(linePositions,socket.getDroneHomePosition().altitude);
      setMissionLine(missionLineEntity);
    */
    }, [props.missionData, socket]);

    const create3DGuideLine = useEffect(() => {
        if (is3DPath) {
            for (let i = 0; i < props.missionData.waypoint.length; i++) {
                draw3DPath(props.missionData.waypoint[i], `3Dpath${i}`);
            }
        }
        return () => {
            for (let i = 0; i < props.missionData.waypoint.length; i++) {
                map.removeObjectById(`3Dpath${i}`);
            }
        };
    }, [is3DPath, props.missionData, socket]);

    return (
        <Container>
            <GuideConsole>Drone mission in progress</GuideConsole>
            <BtnContainer>
                <CancleBtn
                    onClick={() => {
                        endMission();
                    }}
                >
                    Cancle
                </CancleBtn>
                <PathBtn
                    onClick={() => {
                        setIs3DPath(!is3DPath);
                    }}
                >
                    {is3DPath ? "3D Path" : "2D Path"}
                </PathBtn>
            </BtnContainer>
        </Container>
    );
}

export default MissionStart;
