import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  /* height: 18px; */
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`
const Graph = styled.div`
  width: 95%;
  height: 9px;
  /* background: ${props=>props.step>0?`linear-gradient(to right, #0D53E7 ${props.step}%, #282F2F ${0}%)`:'none'}; */
  background: ${props=>`linear-gradient(to right, ${props.color} ${props.step}%, #282F2F ${0}%)`};
  border-radius: 25px;
`
const ItemContaine = styled.ul`
  width: 95%;
  display: flex;
  justify-content: space-between;
`
const Item = styled.li`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.6);
`
function StepGraph({step,color,width}){

  return(
    <Container style={width?{width:`${width}%`}:{}}>
      <Graph step={step} color={color}/>
      <ItemContaine>
        <Item>0%</Item>
        <Item>25%</Item>
        <Item>50%</Item>
        <Item>75%</Item>
        <Item>100%</Item>
      </ItemContaine>
    </Container>
  )
}

export default StepGraph;