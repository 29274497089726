import { useEffect } from "react";
import styled from "styled-components";
import * as THREE from 'three';
import { useDispatch, useSelector } from "react-redux";
import fullScreenImage from "../../../gcs_image/video/video_full_screen.svg"
import minimizeScreenImage from "../../../gcs_image/video/video_minimize.svg"
// import { set_fullscreen } from "../../store/reducer/map";
import { useState } from "react";
const ViewContainer = styled.div`
  position: fixed;
  margin-top: ${props=>props.isFullScreen?'0':'14px'};
  width: ${props=>props.isFullScreen?'100vw':'331px'};
  height: ${props=>props.isFullScreen?'100vh':'227px'};
  top: ${props=>props.isFullScreen?'0':'none'};
  left: ${props=>props.isFullScreen?'-90px':'none'};
  z-index: 6;
  /* position: fixed;
  top: 0;
  left: -90px;
  width: 100vw;
  height: 100vh;
  z-index: 9; */
  /* z-index: ${props=>props.isFullScreen?'99':null}; */
`
const Container = styled.div`
  background-color: #000;
  transition: 2s;
`
const FullScreen = styled.img`
  position: absolute;
  z-index:2;
  /* right: 14px; */
  right: 1.5%;
  /* bottom: 11px; */
  bottom: 3%;
  transition: ${props=>props.isFullScreen?null:'2s'};
  cursor: pointer;
`
const View2D = styled.img`
  position: absolute;
  /* width: ${props=>props.isFullScreen?`${props.browserWidth}px`:'331px'};
  height: ${props=>props.isFullScreen?`${props.browserHeight}px`:'227px'}; */
  width: 100%;
  height: 100%;
`
let camera, scene, renderer;
let material,mesh;
let texture;
let isUserInteracting = false,
  onPointerDownMouseX = 0, onPointerDownMouseY = 0,
  lon = 0, onPointerDownLon = 0,
  lat = 0, onPointerDownLat = 0,
  phi = 0, theta = 0;

let elapsed,now;
let fps = 2;
let fpsInterval = 1000 / fps;
let then = Date.now();
function VideoFrame({selectView,isFullScreen,setIsFullScreen}){

  const videoMode = ['front','down','down'];
  // const {isFullScreen} = useSelector(state=>({
  //   isFullScreen:state.map.isFullScreen,
  // }));
  
  const [errCheck, setErrCheck] = useState(false);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [browserHeight, setBrowserHeight] = useState(window.innerHeight);
  const resizeing = () =>{
    setBrowserWidth(window.innerWidth);
    setBrowserHeight(window.innerHeight);
  }
  useEffect(()=>{
    window.addEventListener('resize',resizeing);
    return () =>{
      window.removeEventListener('resize',resizeing);
    }
  },[])

  useEffect(()=>{
    init();
    animate();
    return ()=>{
      renderer.domElement.remove();
    }
  },[browserWidth,browserHeight,isFullScreen,errCheck])

  const init= ()=> {
    const container = document.getElementById("videoContainer");
    camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 700 );

    scene = new THREE.Scene();

    const geometry = new THREE.SphereGeometry( 500, 60, 40 );
    geometry.scale( - 1, 1, 1 );
    
    texture = new THREE.TextureLoader().load(process.env.REACT_APP_API_URL +'/video/origin/0');

    material = new THREE.MeshBasicMaterial( { map: texture } );
    mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );
    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio( window.devicePixelRatio );
    if(isFullScreen){
      renderer.setSize(browserWidth,browserHeight);
    }
    else{
      renderer.setSize('331', '227' );
    }

    renderer.domElement.addEventListener("webglcontextlost", (event) => {
      event.preventDefault();
    });
    
    renderer.domElement.addEventListener("webglcontextrestored", () => {
      setErrCheck(!errCheck)
    });

    container.appendChild( renderer.domElement );

    container.style.touchAction = 'none';
    container.addEventListener( 'pointerdown', onPointerDown );

    container.addEventListener( 'wheel', onDocumentMouseWheel );
  };

  const onPointerDown = ( event ) => {

    if ( event.isPrimary === false ) return;

    isUserInteracting = true;

    onPointerDownMouseX = event.clientX;
    onPointerDownMouseY = event.clientY;

    onPointerDownLon = lon;
    onPointerDownLat = lat;

    document.addEventListener( 'pointermove', onPointerMove );
    document.addEventListener( 'pointerup', onPointerUp );

  };
  const onPointerMove = ( event ) => {

    if ( event.isPrimary === false ) return;

    lon = ( onPointerDownMouseX - event.clientX ) * 0.1 + onPointerDownLon;
    lat = ( event.clientY - onPointerDownMouseY ) * 0.1 + onPointerDownLat;

  }

  const onPointerUp = ( event ) => {

    if ( event.isPrimary === false ) return;

    isUserInteracting = false;

    document.removeEventListener( 'pointermove', onPointerMove );
    document.removeEventListener( 'pointerup', onPointerUp );

  };

  const onDocumentMouseWheel = ( event ) => {

    const fov = camera.fov + event.deltaY * 0.05;

    camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );

    camera.updateProjectionMatrix();

  };
  const animate = () =>{

    requestAnimationFrame( animate );
    
    update();
  };
  const update=()=> {
    lat = Math.max( - 85, Math.min( 85, lat ) );
    phi = THREE.MathUtils.degToRad( 90 - lat );
    theta = THREE.MathUtils.degToRad( lon );
    const x = 500 * Math.sin( phi ) * Math.cos( theta );
    const y = 500 * Math.cos( phi );
    const z = 500 * Math.sin( phi ) * Math.sin( theta );

    now = Date.now();
    elapsed = now - then;
    if (elapsed > fpsInterval) {
      then = now - (elapsed % fpsInterval);

      const getTexture = () => new Promise((resolve, reject)=>{
        texture = new THREE.TextureLoader().load(process.env.REACT_APP_API_URL +'/video/origin/0');
        THREE.DefaultLoadingManager.onLoad = ()=>resolve(texture);
      });
      getTexture().then(()=>{
        mesh.material.map = texture;
        mesh.material.needsUpdate = true;
      })
    }

    camera.lookAt( x, y, z );
    renderer.render( scene, camera );
  };

  return(
    <ViewContainer isFullScreen={isFullScreen}>
      <Container id='videoContainer'>
        <View2D src={process.env.REACT_APP_API_URL +`/video/${videoMode[selectView]}/0`} style={selectView===2?{zIndex:'-1'}:{zIndex:'1'}} width={browserWidth} height={browserHeight}/>
        <FullScreen src={isFullScreen?minimizeScreenImage:fullScreenImage} onClick={()=>{setIsFullScreen(!isFullScreen)}}/>
      </Container>
    </ViewContainer>
  );
}
export default VideoFrame;
